<template>
  <div>
    <div class="d-none d-sm-flex">
      <v-btn small fab fixed top right class="btnTop" v-if="posAtual>0" @click="goNext(-1)" >
        <v-icon>mdi-chevron-up</v-icon>
      </v-btn>
      <v-btn small fab fixed bottom right v-if="posAtual>0 && posAtual<sections.length-1" @click="goNext(1)" >
        <v-icon>mdi-chevron-down</v-icon>
      </v-btn>
    </div>

    <section id="hero">
      <v-row no-gutters>
        <v-img 
          v-if="standalone"
          src="img/banner-standalone.jpg" 
          :min-height="'calc(100vh - ' + $vuetify.application.top + 'px)'"
        >
          <v-row>
            <v-spacer></v-spacer>
            <v-switch v-model="modoWeb" label="Versão Web" dark dense class="mr-10"/>
          </v-row>
          <div class="pa-4 text-center white--text" style="height: 100%; font-size: 5vmin; display: flex; align-items: center;" >
            O Software MAIS MODERNO de planejamento e automação empresarial na palma das suas mãos!
          </div>
         </v-img>
        <v-img
          v-else
          src="img/banner.jpg" 
          :max-height="'calc(70vh - ' + $vuetify.application.top + 'px)'"
        >
          <div class="pa-4 text-center" style="max-width: 50%; height: 100%; font-size: 4vmin; display: flex; align-items: center;" >
            O Software MAIS MODERNO de planejamento e automação empresarial na palma das suas mãos, pagando MENOS!
          </div>
          <div class="my-play">
            <v-img src="img/googlePlay.png" @click="playStore" />
          </div>
        </v-img>
      </v-row>
    </section>

    <section v-if="!standalone" id="about-me">
      <div class="py-4"></div>
      <v-container class="text-center">
        <v-btn class="mx-5 mb-8" color="green" dark large @click="openWhatsapp">  <!-- to="/apps" -->
          TESTE GRÁTIS AGORA
        </v-btn>
        <v-responsive class="mx-auto title font-weight-light mb-8">
            Sua empresa com uma GESTÃO de ponta, para facilitar a sua vida e a sua rotina.
            Unimos gestão, conhecimento e o que há de MAIS INOVADOR em tecnologia para facilitar o seu dia a dia e gerenciar a sua empresa de maneira eficaz e segura, como você preferir:
            No computador ou no celular!
        </v-responsive>
      </v-container>
    </section>

    <section v-if="!standalone" id="advantages">
      <div class="py-8"></div>
      <v-container class="text-center">
        <h2 class="display-2 font-weight-bold mb-3">11 Vantagens de contratar a ferramenta</h2>
        <v-responsive class="mx-auto mb-12" width="56">
          <v-divider class="mb-1"></v-divider>
          <v-divider></v-divider>
        </v-responsive>
        <v-row>
          <v-col v-for="({ icon, text }, i) in advantages" :key="i" cols="12" md="4" >
            <v-icon large color="green" style="top: -6px;">mdi-check</v-icon>
            <span class="display-1 middle" style="font-size: 1.3rem !important;">{{  text }}</span>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section v-if="!standalone" id="advantages2">
      <div class="py-8"></div>
      <v-container class="text-center">
        <h2 class="display-2 font-weight-bold mb-3">Outros facilitadores</h2>
        <v-responsive class="mx-auto mb-12" width="56">
          <v-divider class="mb-1"></v-divider>
          <v-divider></v-divider>
        </v-responsive>
        <v-row>
          <v-col v-for="({ icon, text }, i) in advantages2" :key="i" cols="12" md="4" >
            <v-icon large color="green" style="top: -6px;">mdi-check-all</v-icon>
            <span class="display-1 middle" style="font-size: 1.3rem !important;">{{  text }}</span>
          </v-col>
        </v-row>
      </v-container>
      <div class="py-8"></div>
    </section>

    <section v-if="!standalone" id="features" class="grey lighten-3">
      <div class="py-8"></div>
      <v-container class="text-center">
        <h2 class="display-2 font-weight-bold mb-3">DIFERENCIAIS</h2>
        <v-responsive class="mx-auto mb-12" width="56">
          <v-divider class="mb-1"></v-divider>
          <v-divider></v-divider>
        </v-responsive>
        <v-row>
          <v-col v-for="({ icon, title, text }, i) in features" :key="i" cols="12" md="4" >
            <v-card class="py-12 px-4" color="grey lighten-5" flat>
              <v-theme-provider dark>
                <div>
                  <v-avatar color="primary" size="88" >
                    <v-icon large v-text="icon"></v-icon>
                  </v-avatar>
                </div>
              </v-theme-provider>
              <v-card-title class="justify-center font-weight-black text-uppercase mytitle" v-text="title"></v-card-title>
              <v-card-text class="subtitle-1" v-text="text"></v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <div class="py-8"></div>
    </section>

    <section v-if="!standalone" id="modules">
      <div class="py-8"></div>
      <v-container>
        <h2 class="display-2 font-weight-bold mb-3 text-uppercase text-center">Conheça o Software por dentro!</h2>
        <v-responsive class="mx-auto mb-12" width="56">
          <v-divider class="mb-1"></v-divider>
          <v-divider></v-divider>
        </v-responsive>
        <v-row justify="center" class="font-weight-light">
          <v-card
            v-for="(item,i) in modules" :key="i"
            class="mx-4 mb-4 modules"
            :color="item.color"
            elevation="5"
            style="width: 300px;"
            @click="functions(item)"
          >
            <v-card-title>
              <v-icon large left>{{ item.icon }}</v-icon>
              <span class="title font-weight-bold">{{ item.name }}</span>
            </v-card-title>
            <v-card-text>{{ substr(item.description) }} ... <small>(clique aqui)</small></v-card-text>
          </v-card>
        </v-row>
      </v-container>
    </section>

    <!-- <v-row v-if="!standalone" class="mt-10 mx-10">
      <v-btn block color="green" dark large @click="contratar(0)">
        Gostou? Faça um teste gratuito
      </v-btn>
    </v-row> -->

    <section v-if="!standalone" id="screenshots">
      <div class="py-8"></div>
      <v-container>
        <h2 class="display-2 font-weight-bold mb-3 text-uppercase text-center">Amostra de telas</h2>
        <v-responsive class="mx-auto mb-12" width="56">
          <v-divider class="mb-1"></v-divider>
          <v-divider></v-divider>
        </v-responsive>
        <v-carousel class="my-carousel" cycle :height="isMobile() ? 200 : 600" show-arrows-on-hover>
          <v-carousel-item v-for="(slide, i) in slides" :key="i" :src="slide"/>
        </v-carousel>
      </v-container>
      <div class="py-4"></div>
    </section>

    <section v-if="isMobile() && !standalone" id="plans">
      <div class="py-8"></div>
      <v-container>
        <h2 class="display-2 font-weight-bold text-uppercase text-center mb-4">Planos</h2>
        <v-responsive class="mx-auto mb-4" width="56">
          <v-divider class="mb-1"></v-divider>
          <v-divider></v-divider>
        </v-responsive>
        <v-row>
          <div class="snip1214">
            <div v-for="({ id, name, price, condition, draft, features }, i) in plans" :key="i">
              <div v-if="i>0" :class="'plan mb-10' + (draft ? ' featured' : '')">
                <div style="height: 40px;">
                  <h3 class="plan-title">{{ name }}</h3>
                  <!-- <div class="plan-cost">
                    <span class="plan-condition">{{ condition }}</span>
                    <span class="plan-sifra">R$ </span>
                    <span class="plan-price">{{ price }}</span>
                    <span class="plan-type">/ mês</span>
                  </div> -->
                </div>
                <ul class="plan-features">
                  <li v-for="(feature, j) in features" :key="j">
                    {{ plans[0].features[j] }}:
                    <span v-if="feature!==1 && feature !==0">{{ feature }}</span>
                    <v-icon v-else-if="draft" color="success">{{ feature === 1 ? 'mdi-check' : 'mdi-close' }}</v-icon>
                    <v-icon v-else color="#008000">{{ feature === 1 ? 'mdi-check' : 'mdi-close' }}</v-icon>
                  </li>
                </ul>
                <div class="plan-select"><a @click="contratar(id)">QUERO CONTRATAR</a></div>
              </div>
            </div>
          </div>
        </v-row>
        <v-row class="plan-obs">
          * Não cobramos taxa de adesão. Valores dos planos por CNPJ.
        </v-row>
        <div class="py-8"></div>
      </v-container>
    </section>
   
    <section v-else-if="!standalone" id="plans">
      <div class="py-8"></div>
      <v-container>
        <h2 class="display-2 font-weight-bold text-uppercase text-center mb-4">Planos</h2>
        <v-responsive class="mx-auto mb-12" width="56">
          <v-divider class="mb-1"></v-divider>
          <v-divider></v-divider>
        </v-responsive>
        <v-row>
          <div class="snip1214">
            <div v-for="({ id, name, price, condition, draft, features }, i) in plans" :key="i" :class="'plan' + (draft ? ' featured' : '')" >
              <div v-if="i>0" style="height: 40px;">
                <h3 class="plan-title">{{ name }}</h3>
                <!-- <div class="plan-cost">
                  <span class="plan-condition">{{ condition }}</span>
                  <span class="plan-sifra">R$ </span>
                  <span class="plan-price">{{ price }}</span>
                  <span class="plan-type">/ mês</span>
                </div> -->
              </div>
              <div v-else style="height: 40px;">
              </div>
              <ul class="plan-features">
                <li v-for="(feature, j) in features" :key="j">
                  <i :class="feature === 1 ? 'ion-checkmark' : ''"></i>
                  {{ feature===1||feature===0 ? '' : feature }}
                </li>
              </ul>
              <div v-if="i>0" class="plan-select"><a @click="contratar(id)">QUERO CONTRATAR</a></div>
            </div>
          </div>
        </v-row>
        <v-row class="plan-obs">
          * Não cobramos taxa de adesão. Valores dos planos para um CNPJ.
        </v-row>
        <div class="py-8"></div>
      </v-container>
    </section>

    <section v-if="!standalone" id="about">
      <div class="py-4"></div>
      <v-container>
        <h2 class="display-2 font-weight-bold mb-3 text-uppercase text-center">Sobre nós</h2>
        <v-responsive class="mx-auto mb-12" width="56">
          <v-divider class="mb-1"></v-divider>
          <v-divider></v-divider>
        </v-responsive>
        <p class="display-1 text-center" style="font-size: 18px !important;">
          Nascemos do desejo de atender a necessidade de um grupo empresarial que não estava satisfeito com os sistemas de gestão oferecidos pelo mercado.
          Após dois anos utilizando e aperfeiçoando o sistema, criamos a empresa Inúmero para oferecer um serviço de qualidade e ajudar outros empresários a transformar suas empresas.
          <small>(Ricardo Reghin, Fundador)</small>
        </p>
      </v-container>
      <div class="py-4"></div>
    </section>

    <section v-if="!standalone" id="stats">
      <v-parallax src="img/parallax.jpg" :height="$vuetify.breakpoint.smAndDown ? 800 : 500">
        <v-container fill-height>
          <v-row class="mx-auto">
            <v-col v-for="[value, title] of stats" :key="title" cols="12" md="4">
              <div class="text-center">
                <div class="display-3 font-weight-regular mb-4" v-text="value" style="font-size: 2em !important;"></div>
                <div class="title font-weight-black" v-text="title" style="font-size: 1.2em !important;"></div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-parallax>
    </section>

    <section v-if="!standalone" id="depositions">
      <div class="py-8"></div>
      <v-container>
        <h2 class="display-2 font-weight-bold mb-3 text-uppercase text-center">Veja o que os nossos clientes dizem…</h2>
        <v-responsive class="mx-auto mb-12" width="56">
          <v-divider class="mb-1"></v-divider>
          <v-divider></v-divider>
        </v-responsive>
        <v-row justify="center" class="font-weight-light">
          <v-card
            v-for="(item,i) in depositions2" :key="i"
            class="mx-4 mb-4"
            color="#f5f5dc"
            elevation="5"
            style="width: 500px;"
          >
            <div class="pa-4">"{{ item.description }}"</div>
            <v-row>
              <v-col class="pr-0">
                <v-img :src="item.logo" height="70" width="140" class="ml-4" color="transparent" />
              </v-col>
              <v-col>
                <div class="font-weight-bold subtitle-1 mr-2">{{ item.name }}</div>
                <div class="subtitle-2 font-italic mr-2"><small>{{ item.office }} {{ item.company }}</small></div>
                <div class="caption font-italic mr-2">{{ item.place }}</div>
              </v-col>
            </v-row>
          </v-card>
          <v-btn dark @click="selectDepositions"><v-icon>mdi-skip-next</v-icon></v-btn>
        </v-row>
      </v-container>
    </section>

    <section v-if="!standalone" id="faq">
      <div class="py-4"></div>
      <v-container>
        <h2 class="display-2 font-weight-bold mb-3 text-uppercase text-center">Perguntas frequentes</h2>
        <v-responsive class="mx-auto mb-12" width="56">
          <v-divider class="mb-1"></v-divider>
          <v-divider></v-divider>
        </v-responsive>
        <v-expansion-panels>
          <v-expansion-panel v-for="(item,i) in faq" :key="i">
            <v-expansion-panel-header>{{ item.question }}</v-expansion-panel-header>
            <v-expansion-panel-content class="mx-6">{{  item.answer }}</v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-container>
      <div class="py-4"></div>
    </section>

    <v-sheet v-if="!standalone" id="contact" color="#333333" dark tag="section" tile>
      <div class="mt-10 py-8"></div>
      <v-container>
        <h2 class="display-1 font-weight-bold mb-3 text-uppercase text-center">Para sua comodidade, deixe seus dados que nossa equipe entrará em contato</h2>
        <v-responsive class="mx-auto mb-12" width="56">
          <v-divider class="mb-1"></v-divider>
          <v-divider></v-divider>
        </v-responsive>
        <v-theme-provider light>
          <v-form ref="formMsg" v-model="valido">
            <v-layout column wrap>
              <v-flex>
                <v-layout wrap>
                  <v-flex col class="pa-0 pr-6" xs12 sm6>
                    <v-text-field v-model="nome" flat label="Nome*" solo :rules="[rules.required, rules.min]"></v-text-field>
                    <v-text-field v-model="email" flat label="Email*" solo :rules="[rules.required, rules.email]"></v-text-field>
                    <v-text-field v-model="assunto" flat label="Assunto*" solo :rules="[rules.required, rules.min]"></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm6>
                    <v-textarea v-model="mensagem" flat label="Messagem*" solo height="204" :rules="[rules.required, rules.min]"></v-textarea>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex row align-self-center>
                <vue-recaptcha
                  sitekey="6Lcrp8QZAAAAAMkuCsMxruXOOCP3uBmL7XzkGWYg"
                  class="mr-10"
                  @verify="(response) => { recaptcha=true; captchaData=response; }"
                  @expired="recaptcha=false"
                />
                <v-btn color="accent" x-large @click="submit">Enviar</v-btn>
              </v-flex>
            </v-layout>
          </v-form>
        </v-theme-provider>
      </v-container>
      <div class="py-8"></div>
    </v-sheet>

    <!--
      Funcionalidade dos Módulos
    -->
    <v-dialog v-model="showModule" v-if="module" width="70%">
      <v-card class="pb-4">
        <v-card-title class="indigo white--text" style="font-size: 1rem;">
          <v-icon color="white" class="mr-2">{{ module.icon }}</v-icon>
          <span> {{ module.name }} </span>
        </v-card-title>
        <v-card-text class="mt-4">{{ module.description }}</v-card-text>
        <h3 class="mx-5 mb-2">Funcionalidades inclusas em todos os planos</h3>
        <v-layout wrap class="mx-5">
          <v-flex xs12 md6 v-for="(func, idx) in module.function" :key="idx">
            <v-icon color="green">mdi-check</v-icon>
            {{ func }}
          </v-flex>
        </v-layout>
        <div v-if="module.others.length > 0">
          <h3 class="mx-5 my-2">Funcionalidades opcionais</h3>
          <v-layout wrap class="mx-5">
            <v-flex xs12 md6 v-for="(func, idx) in module.others" :key="idx">
              <v-icon color="blue">mdi-check</v-icon>
              {{ func }}
            </v-flex>
          </v-layout>
        </div>
        <v-divider class="my-4"></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="showModule=false">Fechar</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import VueRecaptcha from 'vue-recaptcha';
  import axios from 'axios'
  import qs from 'qs'
  import { isMobile } from '@/js/base/misc' 
  
  export default {
    components: { VueRecaptcha },

    data: () => ({
      modoWeb: true,
      sections: ['hero', 'about-me', 'features', 'stats', 'modules', 'screenshots', 'plans', 'contact'],
      posAtual: 0,
      nome: '',
      email: '',
      assunto: 'Inúmero ERP',
      mensagem: 'Tenho interesse em conhecer mais sobre o sistema de gestão Inúmero.',
      valido: false,
      recaptcha: false,
      captchaData: null,
      showModule: false,
      module: null,

      advantages: [
        { icon: '', text: 'Redução de custos'},
        { icon: '', text: 'Economia de tempo'},
        { icon: '', text: 'Flexibilidade'},
        { icon: '', text: 'Personalização'},
        { icon: '', text: 'Eficiência'},
        { icon: '', text: 'Melhoria no relacionamento com o cliente'},
        { icon: '', text: 'Segurança de dados'},
        { icon: '', text: 'Melhoria na gestão de estoque'},
        { icon: '', text: 'Melhoria na colaboração e fluxo de trabalho'},
        { icon: '', text: 'Facilidade de escala'},
        { icon: '', text: 'Relatórios e planejamento aprimorados'}
      ],
      advantages2: [
        { icon: '', text: 'Um aplicativo web de gestão completo, integrando todos os departamentos, sem a necessidade de comprar módulos adicionais'},
        { icon: '', text: 'Aplicativo para celular completo, com os mesmos recursos que a versão web'},
        { icon: '', text: 'Ambiente pré configurado, pronto para utilizar, sem custo de implantação'},
        { icon: '', text: 'Possibilidade de customizar o sistema e alavancar os negócios'},
        { icon: '', text: 'Dezenas de relatórios e gráficos prontos, ferramenta de BI própria e API para integração com PowerBI, Excel e Data Studio'},
        { icon: '', text: 'Integração com várias APIs de terceiros e possibilidade criar outras a baixo custo'}
      ],
      features: [
        {
          icon: 'mdi-account-group-outline',
          title: 'Suporte online',
          text: 'Nossa equipe está sempre disponível para te ajudar no que precisar!',
        },
        {
          icon: 'mdi-update',
          title: 'Atualizações periódicas',
          text: 'Os documentos eletrônicos como NFe, NFCe, CTe, NFSe, DFe são complexos e em constante mudança. Mas não se preocupe, isso é nossa responsabilidade.',
        },
        {
          icon: 'mdi-shield-outline',
          title: 'Desenvolvimento contínuo',
          text: 'Você não ficará amarrado a somente o que estamos oferecendo. Uma equipe de desenvolvedores está ávida para conhecer suas necessidades e ajudar no que for preciso.',
        },
      ],
      stats: [
        ['Missão', 'Com o trabalho conjunto de inúmeros colaboradores, pretendemos progressivamente oferecer aplicativos que atendam as necessidades de um mundo organizado e competitivo'],
        ['Visão', 'Desenvolver aplicativos de alta qualidade utilizando tecnologia de ponta e com excelente custo benefício para nossos clientes'],
        ['Valores', 'Prestigiamos o trabalho árduo, o cooperativismo e a paixão daqueles que não medem esforços para alcançar a excelência']
      ],
      slides: [
        'img/perfil.png',
        'img/manifesto.png',
        'img/painel.png',
        'img/pdv.png',
        'img/pdv_pgto.png',
        'img/nfce.png'
      ],
      depositions: [
        { name: 'Juahil Silva', logo: 'img/technics.png', office: 'Diretor da', company: 'Technics', place: 'Parnamirim / RN', description: 'O sistema Inúmero proporcionou a Technics Turbos mais controle e agilidade nos processos, tornou nosso gerenciamento eficaz, controle sobre as entradas e saídas de produtos, garantindo melhor tomada de decisões e estabilidade financeira da empresa.' },
        { name: 'Rodrigo Pereira', logo: 'img/stadler.png', office: 'Gerente logística da', company: 'Stadler Colchões', place: 'Fazenda Rio Grande / PR', description: 'Quando busco um sistema para gerenciamento de informações na empresa, as principais características que analiso são "confiabilidade, rastreabilidade e praticidade. Sem dúvidas o melhor erp que já utilizei nos meus 20 anos de gestão é o INÚMERO SISTEMAS. Fácil de acessar via celular e PC. Base segura dos dados, diversos relatórios prontos além do fato de ser adaptável a qualquer processo.' },
        { name: 'Mariana Mendes', logo: 'img/gm.png', office: 'Gerência técnica da', company: 'GM Diagnósticos', place: 'Colombo / PR', description: 'O Sistema Inúmero otimizou várias operações dos nossos processos empresariais, seus módulos são fáceis de usar e intuitivos. A equipe de atendimento é excelente, sempre prestativos e possuem muito conhecimento técnico.' },
        { name: 'Giuliano Siqueira', logo: 'img/hiper.png', office: 'Diretor comercial da', company: 'Hiper Peças', place: 'Curitiba / PR', description: 'A perfeita integração entre os departamentos proporcionada pelo sistema Inúmero trouxe simplicidade aos processos e alavancou os resultados.' },
        { name: 'Emely Rosa', logo: 'img/serion.png', office: 'Ass. Adm. da', company: 'Serion Brasil', place: 'Pinhais / PR', description: 'Nossa empresa utiliza o sistema Inúmero desde julho de 2022 e contribuiu muito com agilidade nas demandas em todas as áreas da empresa. É um sistema fácil de adaptar, com funções simples e objetivas. As necessidades de ajustes, em contato com o suporte, fomos sempre bem atendidas de maneira rápida e eficaz para ajudar com a transcendência de nosso serviço prestado.' },
        { name: 'Daniele Franczak', logo: 'img/dct.png', office: 'Ass. comercial da', company: 'DCT Turbos', place: 'Curitiba / PR', description: 'Os recursos utilizados no Inúmero são de "ponta", tornam a navegação fácil e aumentaram muito minha produtividade. Pro Inúmero dou nota 10.' },
        { name: 'João Massaretto', logo: 'img/mr.png', office: 'Ass. logística da', company: 'MR Cosméticos', place: 'Pinhais / PR', description: 'O uso do INÚMERO facilitou nosso processo de fabricação, controle de estoque, saídas de notas fiscais e ajudou nas vendas externas pela praticidade e facilidade de manuseio. É um sistema de alta performance com muita desenvoltura e muito rápido de utilização.' },
      ],
      depositions2: [
      ],
      lastDepositions: -1,
      plans : [
        { id: -1, name: '', price: '0', condition: '', draft: false, features: [
          'Usuários (máximo)',
          'Todos os módulos',
          'Emissão NFe, NFCe',
          'Emissão NFSe',
          'Boleto Bancário',
          'Simples Nacional',
          'Lucro Real / Presumido',
          'Espaço armazenamento',
          'Customizável',
          'Suporte por Whatsapp',
          'Suporte por telefone'
        ]},
        { id: 0, name: 'FREE', price: '0', condition: '', draft: false, features: ['1', 1, 1, 0, 0, 1, 0, '100 M', 0, 1, 0]},
        { id: 1, name: 'BRONZE', price: '340', condition: '', draft: false, features: [10, 1, 1, 1, 1, 1, 0, '1 G', 1, 1, 1]},
        { id: 2, name: 'PRATA', price: '560', condition: '', draft: true, features: [50, 1, 1, 1, 1, 1, 1, '10 G', 1, 1, 1]},
        { id: 3, name: 'OURO', price: '880', condition: '', draft: false, features: ['100+', 1, 1, 1, 1, 1, 1, '20 G', 1, 1, 1]}
      ],
      modules: [
        { name: 'Vendas', icon: 'mdi-sale', color: 'blue lighten-2', description: 'Receba cotações de clientes, transforme em pedidos e crie faturas, notas fiscal e duplicatas. Recursos como cadastro rápido através do CNPJ, integração com Google Maps, QRcode para acompanhamento das entregas, impressão de etiquetas de endereçamento, são alguns dos recursos que ajudarão a obter bons resultados.', function: [
          'Ponto de venda (PDV)',
          'Cotações/Orçamentos e Pedidos de vendas',
          'Cadastro de empresas somente com CNPJ',
          'Integração com Correios para obtenção de CEP e endereço',
          'Múltiplas fotos nos produtos',
          'Configuração tributação por tipo de produto',
          'Cotação de produtos em moeda estrangeira',
          'Controle de produtos com logistica reversa',
          'Emissão de NFe, NFCe, NFSe',
          'Agrupamento de pedidos numa única fatura',
          'Agrupamento de notas numa única fatura',
          'Emissão de boleto bancário',
          'Envio de Pedido por email',
          'Impressão de etiquetas de endereçamento',
          'Link e QRcode para cliente acompanhar a entrega',
          'Cálculo de comissões',
          'Diversos relatórios em PDF'
        ], others: [
          'Driver p/ Impressoras térmicas ESC-POS, Zebra e Argox'
        ]},
        { name: 'Serviços', icon: 'mdi-account-hard-hat', color: 'red lighten-2', description: 'Dê transparência à seus clientes, anexando nas Ordens de serviço fotos do material recebido. Controle os produtos que são condicionados a logística reversa ou base de troca.', function: [
          'Controle de Ordens de serviços',
          'Múltiplas fotos nas ordens de serviços',
          'Laudos de garantia',
          'Quadro para distribuiçao de serviços',
          'Todas as funcionalidades de Vendas'
        ], others: [
        ]},
        { name: 'Indústria', icon: 'mdi-factory', color: 'green lighten-2', description: 'Emita Ordem de fabricação, controle os custos, crie números de série para seus produtos.', function: [
          'Controle de produção com número de Lote, Série ou Validade',
          'Rastreamento por Lote ou Série',
          'Controle de peças utilizadas na fabricação'
        ], others: [
        ]},
        { name: 'Logística', icon: 'mdi-truck-delivery', color: 'orange lighten-2', description: 'Confecção de roteiros de entregas através de recursos por geolocalização. Controle de recolhimento de sucata para sistema de logística reversa.', function: [
          'Roteirização de entregas via geolocalização',
          'Cálculo de distância entre entregas',
          'Visualização das rotas através do Google Maps'
        ], others: [
        ]},
        { name: 'Compras', icon: 'mdi-shopping', color: 'white', description: 'Baixe automaticamente todas as notas e conhecimentos de frete emitidos contra seu CNPJ, adicione as compras ao estoque e registro no contas à pagar. Gere facilmente notas de entrada através da Declarações de Importação.', function: [
          'Importação de XML de NFe/CTe tomadas diretamente da SEFAZ',
          'Importação de arquivo XML de NFSe tomadas',
          'Entrada automática de produtos recebidos',
          'Inclusão automática no contas à pagar',
          'Emissão Nota de entrada através de DI'
        ], others: [
          // 'Importação de XML de NFSe tomadas diretamente das Prefeituras'
        ]},
        { name: 'Estoque', icon: 'mdi-stocking', color: 'yellow lighten-2', description: 'Tenha um histórico de movimentação dos produtos que são comprados, fabricados ou vendidos. Organize-os em um ou mais depósitos. Rastreie os produtos fabricados por número de série e realize inventários.', function: [
          'Múltiplos depósitos',
          'Rastreamento de produtos por Série ou Lote',
          'Histórico de movimentação de peças e produtos',
          'Impressão de etiquetas de produtos',
          'Sugestão de estoque mínimo e produtos com baixa rotatividade',
          'Inventário de produtos'
        ], others: [
          // 'Geração do arquivo Bloco K'
        ]},
        { name: 'Finanças', icon: 'mdi-cash-multiple', color: 'grey lighten-2', description: 'Podemos fazer mais que somente emitir boletos e registrar pagamentos ou recebimentos. Com o arquivo de liquidação de duplicatas, o registro no sistema será automático. Você também pode evitar o desperdício de papel anexando digitalmente os comprovantes de pagamento e extratos aos caixas. Com o gerenciamento de contratos criaremos um fluxo de caixa a médio e longo prazo.', function: [
          'Painel de controle com múltiplos gráficos e planilhas',
          'Demonstração de Resultados do Exercício (DRE)',
          'Controle contas à pagar e à receber',
          'Controle de Contratos',
          'Fluxo de caixa',
          'Exportação de arquivos de cobrança bancários',
          'Importação de arquivos de cobrança bancários e baixa automática de duplicatas pagas'
        ], others: [
          // 'Envio online de boletos de cobrança através de API com o banco',
          // 'Integração de Pagamentos através de API com o banco',
          // 'Baixa automática de extratos bancários'
        ]},
        { name: 'BI', icon: 'mdi-finance', color: 'teal lighten-4', description: 'Ferramenta de Business Inteligence (BI) possibilitando a criação de indicadores chaves para a administração de seu negócio. Criação de relatórios e gráficos dinâmicas que se alteram ao clicar e arrastar um componente.', function: [
          'Exportação dos resultados para formato PDF, EXCEL ou TSV'
        ], others: [
        ]}
      ],
      faq: [
        { question: 'Preciso utilizar uma Conta Google no meu primeiro acesso ?', answer: 'Sim, login pelo Google é o mais seguro atualmente e garante que o email da conta é realmente seu. Após o primeiro acesso você pode entrar no PERFIL do seu usuário e colocar uma senha para ser utilizada nos próximos logins.' },
        { question: 'Esqueci minha senha, como recuperá-la ?', answer: 'Faça login utilizando sua conta Google, entre no PERFIL do seu usuário e coloque uma nova senha.' },
        { question: 'Posso cancelar meu plano a qualquer momento ?', answer: 'Sim, não há período de fidelidade e cobraremos somente os dias utilizados.' },
        { question: 'Como é calculado a quantidade de usuários do meu plano ?', answer: 'Para efeito de plano de serviço contratado, consideramos somente os usuários ativos, ou seja, usuários que fizeram login no sistema pelo menos uma vez no mês.' },
        { question: 'Preciso efetuar pagamento antecipado ?', answer: 'Não, pode utilizar o sistema o mês inteiro, pois a fatura de cobrança vencerá somente no 10º dia do mês seguinte.' },
        { question: 'Se eu atrasar o pagamento o que acontece ?', answer: 'O sistema entra em modo de CONSULTA, ou seja, será possível pesquisar todas as informações, mas não será possível alterá-las.' },
        { question: 'Como posso pedir ajuda ?', answer: 'Ligue ou envie uma mensagem para o whatsapp (41) 3344-5444. Se desejar pode enviar um email para suporte@inumero.com.br' },
        { question: 'Posso ter ajuda de especialistas ?', answer: 'Sim, entre em contato com o suporte para entedermos qual sua necessidade. Temos especialista nas áreas de TI, finanças, organização e métodos, logística, entre outras. Solicite um orçamento.' },
        { question: 'O sistema Inúmero é nas "nuvens" ?', answer: 'Sim, todos os dados ficam armazenados em nossos servidores e serão acessados por você pela internet.' },
        { question: 'Preciso fazer cópia de segurança ?', answer: 'Não, nós fazemos backup de suas informações de vários maneiras, como: servidores de redundância, replicação de banco de dados, sincronização de arquivos anexados, registro de alterações, entre outras coisas para garantir a segurança de seus dados.' },
        { question: 'O sistema funciona 24h por dia ?', answer: 'Sim, 24h por dia, 7 dias por semana. Contudo, vocẽ pode restringuir o acesso de determinados usuários para o período que desejar. Entre em contato com o suporte.' },
      ],
      rules: {
        required: value => !!(value || '').trim() || 'Obrigatório',
        min: v => (v || '').trim().length >= 8 || 'Minimo 8 caracteres',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Email inválido'
        }
      }
    }),

    computed: {
      ...mapState(['user', 'standalone'])
    },

    created () {
      // var that = this
      // setInterval(function () { 
      //   that.selectDepositions()
      // }, 10000)
      this.selectDepositions()
      this.modoWeb = !this.standalone
    },

    activated () {
      if (this.user) {
        if (!this.nome)
          this.nome = this.user.displayName
        if (!this.email)
          this.email = this.user.email
      }
    },

    watch: {
      modoWeb (val) {
        this.$store.commit('SET_STANDALONE', !val)
      }
    },

    methods: {
      playStore () {
        window.open('https://play.google.com/store/apps/details?id=xyz.appmaker.xnpajj')
      },

      canalYoutube () {
        window.open('https://www.youtube.com/channel/UCBCJpzV38CCUO0lN2gZGp3w/videos?view=0&sort=da', '_blank')
      },

      goNext(val) {
        this.posAtual += val
        this.$vuetify.goTo('#'+this.sections[this.posAtual])
      },

      substr (val) {
        let pos = val.substr(0, 100).lastIndexOf(' ')
        return val.substr(0, pos)
      },

      selectDepositions () {
        this.depositions2 = []
        let i
        for (i = 0; i < 3; i++) {
          this.lastDepositions += 1
          if (this.lastDepositions >= this.depositions.length) {
            this.lastDepositions = 0
          }
          this.depositions2.push( this.depositions[this.lastDepositions])
        }
      },

      submit () {
        if (this.$refs.formMsg.validate()) {
          if (this.recaptcha) {
            var that = this
            document.body.setAttribute('style', 'cursor: wait;')
            axios({ 
              method: 'POST',
              url: this.$store.state.serverURI + 'faleConosco.php',
              data: qs.stringify({
                nome: this.nome,
                email: this.email,
                assunto: this.assunto,
                mensagem: this.mensagem,
                captchaData: this.captchaData
              })
            })
              .then(function (response) {
                if (response.data.ok) {
                  alert('Recebemos sua mensagem. Em breve responderemos no email informado. Obrigado por seu contato.')
                  that.assunto = ''
                  that.mensagem = ''
                } else {
                  alert('Ops!! Infelizmente sua mensagem não foi enviada. ' + response.data)
                }
              })
              .catch(function (error) {
                  alert('Ops!! Ocorreu um erro: ' + (error.message || ''))
              })
              .finally(function () {
                document.body.setAttribute('style', 'cursor: default;')
              })
          } else
            alert('Clique em "Não sou um robô"')
        } else
          alert('Preencha todos os campos corretamente!')
      },

      contratar (id) {
        this.$router.push('/apps?add=' + id)
      }, 

      functions (item) {
        this.module = item
        this.showModule = true
      },

      isMobile () {
        return isMobile()
      },

      openWhatsapp () {
        let url = '.whatsapp.com/send?phone=554133445444&text=Olá, me chamo ..... e desejo testar o aplicativo Inúmero.'
        url = 'https://' + (isMobile() ? 'api' : 'web') + url
        window.open(url, '_blank')
      }
    }
  }
</script>

<style scoped>
  .help {
    cursor: help !important;
  }
  .pointer {
    cursor: pointer !important;
  }
  #fluxogr {
    max-width: 800px;
  }
  .modules {
    cursor: pointer;
  }
</style>

<style scoped>
.mensalidade {
  font-size: 18px;
}
.btnTop {
  margin-top: 80px;
}
.mytitle {
  word-break: normal;
}
.my-carousel {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
}
@media only screen and (max-width: 767px) {
  .my-carousel {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}
@import url(https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,500,800);
@import url(https://fonts.googleapis.com/css?family=Montserrat:800);
.snip1214 {
  font-family: 'Raleway', Arial, sans-serif;
  color: #000000;
  text-align: center;
  font-size: 16px;
  width: 100%;
  margin: 20px 10px;
}
.snip1214 .plan {
  margin: 0;
  width: 20%;
  position: relative;
  float: left;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}
.snip1214 * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.snip1214 header {
  position: relative;
}
.snip1214 .plan-title {
  position: relative;
  top: 0;
  font-weight: 800;
  padding: 5px 15px;
  margin: 0 auto;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0;
  display: inline-block;
  background-color: #222f3d;
  color: #ffffff;
  text-transform: uppercase;
}
.snip1214 .plan-cost {
  padding: 0px 10px 20px;
}
.snip1214 .plan-sifra {
  font-family: 'Montserrat', Arial, sans-serif;
  font-weight: 800;
  font-size: 1.4em;
  color: #34495e;
}
.snip1214 .plan-price {
  font-family: 'Montserrat', Arial, sans-serif;
  font-weight: 800;
  font-size: 1.8em;
  color: #34495e;
}
.snip1214 .plan-type {
  opacity: 0.6;
}
.snip1214 .plan-condition {
  opacity: 0.6;
  position: absolute;
  margin-top: -12px;
}
.snip1214 .plan-features {
  padding: 0;
  margin: 0;
  text-align: center;
  list-style: outside none none;
  font-size: 0.8em;
}
.snip1214 .plan-features li {
  border-top: 1px solid #d2d7e2;
  padding: 10px 5%;
}
.snip1214 .plan-features li:nth-child(even) {
  background: rgba(0, 0, 0, 0.08);
}
.snip1214 .plan-features i {
  margin-right: 8px;
  opacity: 0.4;
}
.snip1214 .plan-select {
  border-top: 1px solid #d2d7e2;
  padding: 10px 10px 0;
}
.snip1214 .plan-select a {
  background-color: green;  /* #222f3d; */
  color: #ffffff;
  text-decoration: none;
  padding: 0.5em 1em;
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
  font-weight: 800;
  text-transform: uppercase;
  display: inline-block;
}
.snip1214 .plan-select a:hover {
  background-color: #46627f;
}
.snip1214 .featured {
  background-color: #34495e;
  color: #ffffff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
  z-index: 1;
}
.snip1214 .featured .plan-title,
.snip1214 .featured .plan-price,
.snip1214 .featured .plan-sifra{
  color: #ffffff;
}
.snip1214 .featured .plan-cost .plan-obs {
  padding: 10px 10px 20px;
}
.plan-obs {
  padding-left: 10px;
  padding-right: 10px;
}
.snip1214 .featured .plan-features li {
  border-top: 1px solid rgba(255, 255, 255, 0.4);
}
.snip1214 .featured .plan-select {
  padding: 20px 10px 0;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
}
@media only screen and (max-width: 767px) {
  .snip1214 .plan {
    width: 50%;
  }
  .snip1214 .plan-title,
  .snip1214 .plan-select a {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  .snip1214 .plan-cost,
  .snip1214 .featured .plan-cost {
    padding: 10px;
  }
  .snip1214 .plan-select,
  .snip1214 .featured .plan-select {
    padding: 10px 10px 10px;
  }
  .snip1214 .featured {
    margin-top: 0;
  }
}
@media only screen and (max-width: 440px) {
  .snip1214 .plan {
    width: 100%;
  }
}
.my-play {
  max-width: 30%;
  position: absolute;
  right: 0px;
  bottom: 0;
  z-index: 1;
  cursor: pointer;
}
</style>