<template>
  <div>
    <v-card class="mx-10 my-10">
      <v-toolbar dark>
        <v-toolbar-title>Meus aplicativos</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon :loading="loading" @click="atualizar">
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        <v-btn icon @click="adicionar">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>

      <v-container fluid grid-list-md>
        <v-layout wrap>
          <v-flex v-if="records.length == 0" xs12>
            <div class="py-10 text-center" @click="adicionar">
              Nenhum aplicativo instalado. Clique aqui para adicionar seu primeiro aplicativo.
            </div>
          </v-flex>
          <v-flex v-else xs12 sm6 md3 v-for="(record, idx) in records" :key="idx" >
            <v-card>
              <v-img
                :src="record.foto || 'img/inumero-150x100.png'"
                class="white--text align-end"
                gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                height="200px"
                :style="'cursor: ' + (record.bloqueado=='1' ? 'default;' : 'pointer;')"
                @click="abrir(record.id)"
              >
                <v-card-title v-text="getPlan(record)" style="font-size: 0.8rem;"></v-card-title>
              </v-img>
              <v-card-actions>
                <span><b class="mr-4" style="font-size: 1rem;">{{ record.nome }}</b></span>
                <v-spacer></v-spacer>
                <v-btn v-if="record.bloqueado=='0'" icon @click="abrir(record.id)"><v-icon>mdi-open-in-app</v-icon></v-btn>
                <v-icon v-else color="red" class="mr-4">mdi-lock</v-icon>
                <v-btn v-if="record.plano<9" icon @click="editar(record.id)"><v-icon>mdi-pencil</v-icon></v-btn>
                <v-btn v-if="record.status==1 && record.plano<4" icon @click="upgrade(record.id)"><v-icon>mdi-cart-arrow-up</v-icon></v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>

    <v-card v-if="invites.length > 0" class="mx-10 my-10">
      <v-toolbar dark dense>
        <v-toolbar-title>Convites</v-toolbar-title>
      </v-toolbar>
      <v-expansion-panels v-model="instuId">
        <v-expansion-panel v-for="(invite, idx) in invites" :key="idx">
          <v-expansion-panel-header style="padding: 0;">
            <v-container grid-list-md>
              <v-layout flex wrap align-center justify-center>
                <v-flex xs12 sm2><v-avatar><v-img :src="invite.foto || 'img/inumero-150x100.png'" /></v-avatar></v-flex>
                <v-flex xs12 sm2><small>{{ invite.data | prettyDate }}</small></v-flex>
                <v-flex xs12 sm3><b>{{ invite.app_nome }} - {{ invite.nome }}</b></v-flex>
                <v-flex xs12 sm3><div style="color: red;">Expira em {{ invite.expira | prettyDate }}</div></v-flex>
                <v-flex xs12 sm2><v-btn dark small class="mr-10" :loading="ativando" @click="ativar(invite, idx)">Ativar</v-btn></v-flex>
              </v-layout>
            </v-container>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div v-html="invite.texto"/>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>

    <!--
        ADICIONAR APLICATIVOS
    -->
    <v-dialog v-model="dialogAdd" persistent max-width="600px">
      <v-container>
        <v-card>
          <v-card-title>
            <span class="headline">CRIAR EMPRESA</span>
          </v-card-title>
          <v-card-text>
            <v-select
              v-model="dPlan"
              id="plano_contratar"
              label="Plano à contratar"
              :items="plans"
              hide-details
              dense
              outlined
            >
              <template v-slot:selection="{ item }">
                <v-layout class="mt-4" align-center>
                  <span class="plan-title">{{ item.name }}</span>
                  <!-- <span class="plan-cost">
                    <span class="plan-condition">{{ item.condition }}</span>
                    <span class="plan-sifra">R$ </span>
                    <span class="plan-price">{{ item.price }}</span>
                    <span class="plan-type">/ mês</span>
                  </span> -->
                </v-layout>
              </template>
            </v-select>
            <v-text-field
              v-model.trim="dName"
              label="Nome da empresa"
              class="mt-4"
              :hint="(dName || '').length > 0 ? '' : 'Máximo 20 letras'"
              :maxlength="20"
              :rules="[(v) => (v || '').length >= 5 || 'Mínimo 5 letras']"
            />
            <v-row class="my-4">
              <v-text-field
                v-model.trim="dCelular"
                label="Celular (WhatsApp)"
                dense
                style="margin-left: 14px; margin-right: 10px;"
                :rules="[mascaraTel]"
              />
              <v-text-field
                v-model.trim="dCnpj"
                label="CPF / CNPJ"
                dense
                class="mx-4"
                :maxlength="20"
                :rules="[rulesCnpj]"
              />
            </v-row>
            <div v-if="!dPhoto" class="mt-2">
              Clique p/ adicionar sua logo
            </div>
            <v-image-input
              ref="photoAdd"
              v-model="dPhoto"
              class="my-0"
              :image-height="90"
              :image-width="120"
              :image-quality="0.85"
              clearable
            />
          </v-card-text>
          <v-card-actions class="less-top">
            <v-col>
              <v-checkbox class="pa-0 ma-0" v-model="politica" label="Li e aceito a Política de Privacidade" @click="politica=true; dlgPolitica=true"/>
              <v-checkbox class="pa-0 ma-0" v-model="termo" label="Li e aceito o Contrato de uso" @click="termo=true; dlgTermo=true"/>
              <v-row>
                <v-spacer></v-spacer>
                <v-btn class="mx-4" color="primary" :disabled="(!termo) || (!politica)" @click="salvarAdd" :loading="loading">Salvar</v-btn>
                <v-btn class="mx-4" @click="dialogAdd = false">Cancelar</v-btn>
                <v-spacer></v-spacer>
              </v-row>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-container>
    </v-dialog>

    <!--
        EDITAR APLICATIVO
    -->
    <v-dialog v-model="dialogEdit" persistent max-width="600px">
      <v-container>
        <v-card class="pb-4">
          <v-card-title>
            <span class="headline">EDITAR EMPRESA</span>
          </v-card-title>
          <v-card-text>
            <v-text-field
              v-model.trim="dName"
              label="Nome da empresa"
              hint="Máximo 20 letras"
              dense
              :maxlength="20"
              :rules="[(v) => (v || '').length >= 5 || 'Mínimo 5 letras']"
            />
            <v-row>
              <v-text-field
                v-model.trim="dCelular"
                label="Celular (WhatsApp)"
                dense
                style="margin-left: 14px; margin-right: 10px;"
                :rules="[mascaraTel]"
              />
              <v-text-field
                v-model.trim="dCnpj"
                label="CPF / CNPJ"
                dense
                class="mx-4"
                :maxlength="20"
                :rules="[rulesCnpj]"
                :disabled="disCnpj"
              />
            </v-row>
            <v-row>
              <v-switch 
                v-model.trim="dLock"
                label="Bloquear acesso"
                style="margin-left: 14px;"
                dense
              />
              <v-spacer/>
              <v-btn dark color="red" class="mt-4 mx-4" @click="excluirInst">
                <v-icon>mdi-delete</v-icon>
                Excluir
              </v-btn>
            </v-row>
            <v-image-input
              ref="photoEdit"
              v-model="dPhoto"
              :image-height="90"
              :image-width="120"
              :image-quality="0.85"
              class="mt-4"
              clearable
            />
            <div v-if="!dPhoto" class="img-input">
              Clique para adicionar uma imagem
            </div>
          </v-card-text>
          <v-card-actions class="mr-8">
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="salvarEdit">Salvar</v-btn>
            <v-btn class="ml-4" @click="dialogEdit = false">Cancelar</v-btn>
          </v-card-actions>
        </v-card>
      </v-container>
    </v-dialog>

    <!--
        UPGRADE DE PLANO
    -->
    <v-dialog v-model="dialogUpg" persistent max-width="600px">
      <v-container>
        <v-card class="pb-4">
          <v-card-title>
            <span class="headline">ALTERAR PLANO</span>
          </v-card-title>
          <v-card-text>
            <v-select
              v-model="dPlan"
              id="plano_contratar2"
              label="Plano atual"
              :items="plans"
              class="mb-10"
              hide-details
              dense
              outlined
              :disabled="true"
              filled
            >
              <template v-slot:selection="{ item }">
                <v-layout class="mt-4" align-center>
                  <span color="red" class="plan-title">{{ item.name }}</span>
                  <!-- <span class="plan-cost">
                    <span class="plan-condition">{{ item.condition }}</span>
                    <span class="plan-sifra">R$ </span>
                    <span class="plan-price">{{ item.price }}</span>
                    <span class="plan-type">/ mês</span>
                  </span> -->
                </v-layout>
              </template>
            </v-select>

            <v-select
              v-model="dPlanUpg"
              id="plano_contratar3"
              label="Alterar Plano para"
              :items="plans"
              class="mb-10"
              hide-details
              dense
              outlined
            >
              <template v-slot:selection="{ item }">
                <v-layout class="mt-4" align-center>
                  <span class="plan-title">{{ item.name }}</span>
                  <!-- <span class="plan-cost">
                    <span class="plan-condition">{{ item.condition }}</span>
                    <span class="plan-sifra">R$ </span>
                    <span class="plan-price">{{ item.price }}</span>
                    <span class="plan-type">/ mês</span>
                  </span> -->
                </v-layout>
              </template>
            </v-select>
            <v-row>
              <v-text-field
                v-model.trim="dCelular"
                label="Celular (WhatsApp)"
                dense
                style="margin-left: 14px; margin-right: 10px;"
                :rules="[mascaraTel]"
              />
              <v-text-field
                v-model.trim="dCnpj"
                label="CPF / CNPJ"
                dense
                class="mx-4"
                :maxlength="20"
                :rules="[rulesCnpj]"
                :disabled="disCnpj"
              />
            </v-row>
          </v-card-text>
          <v-card-actions class="mx-8">
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="salvarUpg">Salvar</v-btn>
            <v-btn class="ml-4" @click="dialogUpg = false">Cancelar</v-btn>
          </v-card-actions>
        </v-card>
      </v-container>
    </v-dialog>

    <!--
        POLITICA DE PRIVACIDADE
    -->
    <v-dialog v-model="dlgPolitica" persistent>
      <v-card>
        <v-card-title class="indigo white--text" style="font-size: 1rem;">
          <v-icon color="white" class="mr-2">mdi-file-document</v-icon>
          <span>POLÍTICA DE PRIVACIDADE</span>
          <v-spacer></v-spacer>
          <v-btn dark class="toolbtn mr-2" icon @click="print('politica')">
            <v-icon>mdi-printer</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <my-politica :titlable="false" />
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="mr-8">
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dlgPolitica=false">Aceito</v-btn>
          <v-btn class="ml-4" @click="politica=false; dlgPolitica=false;">Recuso</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--
        CONTRATO DE USO
    -->
    <v-dialog v-model="dlgTermo" persistent>
      <v-card>
        <v-card-title class="indigo white--text" style="font-size: 1rem;">
          <v-icon color="white" class="mr-2">mdi-file-document</v-icon>
          <span>CONTRATO DE USO</span>
          <v-spacer></v-spacer>
          <v-btn dark class="toolbtn mr-2" icon @click="print('contrato')">
            <v-icon>mdi-printer</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <my-contrato :titlable="false" />
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="mr-8">
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dlgTermo=false">Aceito</v-btn>
          <v-btn class="ml-4" @click="termo=false; dlgTermo=false;">Recuso</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import ImageInput from 'vuetify-image-input'
  import ajax from '@/js/base/ajax'
  import { mascaraCpfCnpj, isCpfCnpj, setCookie, mascaraTel } from '@/js/base/misc'
  import store from '@/store'
  import Politica from '@/components/Politica.vue'
  import Contrato from '@/components/Contrato.vue'

  export default {
    components: {
      'v-image-input': ImageInput,
      'my-politica': Politica,
      'my-contrato': Contrato
    },

    data: () => ({
      loading: false,
      ativando: false,
      instuId: null,
      dialogAdd: false,
      dialogEdit: false,
      dialogUpg: false,
      dPlan: 0,
      dPlanUpg: 0,
      disCnpj: false,
      dName: '',
      dCnpj: '',
      dCelular: '',
      dPhoto: null,
      dLock: false,
      editID: 0,
      index: 0,
      records: [],
      invites: [],
      politica: false,
      termo: false,
      dlgPolitica: false,
      dlgTermo: false,
      plans: [
        { value: 0, text: 'FREE', name: 'FREE', price: '0', condition: '', disabled: false},
        { value: 1, text: 'BRONZE', name: 'BRONZE', price: '340', condition: '', disabled: false},
        { value: 2, text: 'PRATA', name: 'PRATA', price: '560', condition: '', disabled: false},
        { value: 3, text: 'OURO', name: 'OURO', price: '880', condition: '', disabled: false},
        // { value: 4, text: 'DIAMANTE: R$ 1499/mês', name: 'DIAMANTE', price: '1499', condition: 'a partir de', disabled: false}
      ]
    }),

    mounted () {
      this.atualizar()
     },

    activated() {
      let addPlan = new URL(window.location.href).searchParams.get('add')
      if (addPlan) {
        this.dPlan = parseInt(addPlan)
        this.adicionar()
      }
    },

    methods: {
      mascaraTel (val) {
        let v = mascaraTel(val)
        if (v != val)
          this.dCelular = v
        let l = (v || '').length
        return (l >= 14 && l <= 15) || 'Informe um nº válido'
      },

      atualizar () {
        var vm = this
        this.loading = true
        var myInvited = []
        var recLoaded = false
        var invLoaded = false

        ajax.request('crud.php', { name: 'main', service: 'search', searchText: 'Todos' },
          response => {
            recLoaded = true
            vm.records = response.data.records
            if (invLoaded) {
              for (let record of myInvited)
                vm.records.splice(vm.records.length, 0, record)
            }
          }
        )

        ajax.request('crud.php', { name: 'invited', service: 'search', searchText: 'Todos' },
          response => {
            if (recLoaded) {
              for (let record of response.data.records)
                vm.records.splice(vm.records.length, 0, record)
            } else {
              invLoaded = true
              myInvited = response.data.records
            }
          }
        )

        ajax.request('crud.php', { name: 'invites', service: 'search', searchText: 'Todos' },
          response => {
            vm.invites = response.data.records
          },
          null,
          () => {
            vm.loading = false
          }
        )
      },

      getRecord (id) {
        let index = -1
        for (let idx in this.records) {
          if (this.records[idx].id == id) {
            index = idx
            break
          }
        }
        return index
      },

      getPlan (record) {
        let text = ''
        for (let item of this.plans) {
          if (item.value == record.plano) {
            text = item.name
            break
          }
        }
        text = record.app_nome + (text ? ' (' + text + ')' : '')
        if (record.mensagem)
          text += ' ' + record.mensagem
        return text
      },
      
      rulesCnpj (val) {
        let v = mascaraCpfCnpj(val)
        if (v != val)
          this.dCnpj = v
        return !v || isCpfCnpj(v) || 'Número inválido'
      },

      adicionar () {
        this.politica = false
        this.termo = false
        this.dName = ''
        this.dCnpj = ''
        this.dCelular = ''
        if (this.dPhoto && this.$refs.photoAdd)
          this.$refs.photoAdd.clear()
        this.dialogAdd = true
        setTimeout( () => { document.getElementById('plano_contratar').style.display = 'none' }, 500)
      },

      salvarAdd () {
        let l = (this.dCelular || '').length
        if (this.dPlan == null || this.dPlan === undefined)
          store.commit('ADD_ALERT', 'Selecione um Plano')
        else if ((this.dName || '').trim().length < 5)
          store.commit('ADD_ALERT', 'Nome da empresa inválido')
        else if (l < 14 || l > 15)
          store.commit('ADD_ALERT', 'Celular inválido')
        else if (this.dPlan > 0 && (this.dCnpj || '').trim().length < 11 && !isCpfCnpj(this.dCnpj))
          store.commit('ADD_ALERT', 'CPF / CNPJ inválido')
        else {
          var that = this
          this.loading = true
          ajax.request(
            'ajax.php',
            { 
              name: 'main', service: 'addInst', 
              record: {plan: this.dPlan, name: this.dName, cnpj: this.dCnpj, celular: this.dCelular, photo: this.dPhoto}
            },
            response => {
              that.loading = false
              if (response.data.id) {
                that.records.push({
                  id: response.data.id,
                  status: response.data.status,
                  app_id: response.data.app_id,
                  app_nome: response.data.app_nome,
                  nome: this.dName,
                  cnpj: this.dCnpj,
                  celular: this.dCelular,
                  plano: this.dPlan,
                  foto: this.dPhoto, 
                  mensagem: response.data.mensagem,
                  bloqueado: response.data.bloqueado,
                  serverhost: response.data.serverhost,
                  token: response.data.token
                })
                that.dialogAdd = false
                var mensagem = ''
                // if (this.dPlan > 0) {
                //   mensagem = '<BR>A instalação do plano adquirido será concluída em aproxidamente 24h, contudo você já pode utilizar o sistema. Obrigado por confiar em nossos produtos.'
                // }
                this.$nextTick(() => ( this.abrirAgora(response.data.id, mensagem) ))
              }
            }
          )
        }
      },

      async abrirAgora (id, mensagem) {
        if (await this.$confirm(mensagem + '<BR>Deseja iniciar seu novo aplicativo agora?')) {
          this.abrir(id)
        }
      },

      abrir (id) {
        let idx = this.getRecord(id)
        let record = this.records[idx]
        if ((!record.bloqueado) || (record.bloqueado=='0')) {
          setCookie('token', this.$store.state.token)
          setCookie('instance', record.token)
          setCookie('instanceId', id)
          //localStorage.setItem('token', JSON.stringify(this.$store.state.token))
          window.location.href = record.serverhost
        }
      },

      editar (id) {
        this.editID = id
        this.index = this.getRecord(id)
        var record = this.records[this.index]
        this.dName = record.nome
        this.dCnpj = record.cnpj
        this.dCelular = record.celular
        this.dLock = record.bloqueado == '1'
        this.dialogEdit = true
        this.$nextTick(function () {
          if (record.foto)
            this.dPhoto = record.foto
          else if (this.$refs.photoAdd)
            this.$refs.photoEdit.clear()
        })
      },

      salvarEdit () {
        let l = (this.dCelular || '').length
        if (this.dPlan == null || this.dPlan === undefined)
          store.commit('ADD_ALERT', 'Selecione um Plano')
        else if ((this.dName || '').trim().length < 5)
          store.commit('ADD_ALERT', 'Nome da empresa inválido')
        else if (l < 14 || l > 15)
          store.commit('ADD_ALERT', 'Celular inválido')
        else if (this.dPlan > 0 && (this.dCnpj || '').trim().length < 11 && !isCpfCnpj(this.dCnpj))
          store.commit('ADD_ALERT', 'CPF / CNPJ inválido')
        else {
          var that = this
          ajax.request(
            'ajax.php',
            { name: 'main', service: 'editInst', record: {
              id: this.editID,
              name: this.dName,
              cnpj: this.dCnpj,
              celular: this.dCelular,
              photo: this.dPhoto,
              lock: this.dLock ? '1' : '0'
            }},
            response => {
              if (response.data.ok) {
                that.records[this.index].nome = this.dName
                that.records[this.index].cnpj = this.dCnpj
                that.records[this.index].celular = this.dCelular
                that.records[this.index].foto = this.dPhoto
                that.records[this.index].bloqueado = this.dLock ? '1' : '0'
                that.dialogEdit = false
              }
            }
          )
        }
      },

      async excluirInst () {
        if (await this.$confirm('<BR>Deseja EXCLUIR esta empresa?')) {
          if (await this.$confirm('<BR>Todos os dados serão DEFINITIVAMENTE apagados. Deseja realmente EXCLUIR?')) {
            var that = this
            ajax.request(
              'ajax.php',
              { name: 'main', service: 'delInst', record: { id: this.editID }},
              response => {
                if (response.data.ok) {
                  this.records.splice(this.index, 1)
                  that.dialogEdit = false
                }
              }
            )
          }
        }
      },

      upgrade (id) {
        this.editID = id
        this.index = this.getRecord(id)
        var record = this.records[this.index]
        this.dPlan = parseInt(record.plano)
        this.dPlanUpg = this.dPlan + 1
        this.dCnpj = record.cnpj
        this.dCelular = record.celular
        this.disCnpj = !!this.dCnpj
        for (let plan of this.plans) {
          plan.disabled = plan.value <= this.dPlan
        }
        this.dialogUpg = true
        setTimeout( () => { 
          document.getElementById('plano_contratar2').style.display = 'none' 
          document.getElementById('plano_contratar3').style.display = 'none' 
        }, 500)
      }, 

      salvarUpg () {
        let l = (this.dCelular || '').length
        if (this.dPlanUpg == null || this.dPlanUpg === undefined)
          store.commit('ADD_ALERT', 'Selecione um Plano')
        else if (l < 14 || l > 15)
          store.commit('ADD_ALERT', 'Celular inválido')
        else if ((this.dCnpj || '').trim().length < 11 && !isCpfCnpj(this.dCnpj))
          store.commit('ADD_ALERT', 'CPF / CNPJ inválido')
        else {
          var that = this
          ajax.request(
            'ajax.php',
            { name: 'main', service: 'upgPlan', record: {
              id: this.editID,
              plano: this.dPlanUpg,
              cnpj: this.dCnpj,
              celular: this.dCelular
            }},
            response => {
              if (response.data.ok) {
                that.dPlan = this.dPlanUpg
                that.records[this.index].plano = this.dPlan
                that.records[this.index].cnpj = this.dCnpj
                that.records[this.index].celular = this.dCelular
                that.records[this.index].status = response.data.status
                that.records[this.index].mensagem = response.data.mensagem
                that.dialogUpg = false
                store.commit('CAPTION_ERROR', 'ALTERAÇÃO DE PLANO')
                store.commit('ADD_ERROR', 'A alteração do seu plano deverá ser concluída em aproxidamente 24h. Obrigado por confiar em nossos produtos.')
              }
            }
          )
        }
      },

      ativar (invite, idx) {
        var that = this
        var index = idx
        this.ativando = true
        ajax.request(
          'ajax.php',
          { name: 'invites', service: 'activateInst', id: invite.instUserId },
          response => {
            if (response.data.ok) {
              that.instuId = null
              that.records.splice(that.records.length, 0, that.invites[index])
              that.invites.splice(index, 1)
            }
          },
          null,
          () => {
            that.ativando = false
          }
        )
      },

      print (name) {
        let w = window.open()
        let html = document.getElementById(name).innerHTML
        let title = '<head><title>INÚMERO</title></head><h3>'
        if (name == 'politica') 
          title += 'POLÍTICA DE PRIVACIDADE'
        else
          title += 'CONTRATO DE USO'
        title += ' - INÚMERO SISTEMAS</h3>'
        w.document.write(title + html)
        w.print()
        w.close()
      }
    }
  }
</script>

<style scoped>
.plan-title {
  font-size: 2.0em;
  font-weight: 800;
  padding: 5px 15px;
  margin-top: 10px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  display: inline-block;
  background-color: #222f3d;
  color: #ffffff;
  text-transform: uppercase;
}
.plan-cost {
  padding: 0px 10px 20px;
}
.plan-sifra {
  font-family: 'Montserrat', Arial, sans-serif;
  font-weight: 800;
  font-size: 1.4em;
  color: #34495e;
}
.plan-price {
  font-family: 'Montserrat', Arial, sans-serif;
  font-weight: 800;
  font-size: 1.8em;
  color: #34495e;
}
.plan-type {
  opacity: 0.6;
}
.plan-condition {
  opacity: 0.6;
  position: absolute;
  font-size: 0.8em;
  margin-top: -18px;
}
.less-top {
  top: -25px;
  position: relative;
}
</style>
