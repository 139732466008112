<template>
  <v-container fluid fill-height class="py-4">
    <v-layout flex align-center justify-center>
      <v-card width="700">
        <v-toolbar dark color="red">
          <v-toolbar-title class="white--text text-center"><h4>EXCLUIR CONTA DE ACESSO</h4></v-toolbar-title>
        </v-toolbar>
        <v-card-title class="ma-8 my-title">
          Sua conta, bem como seus dados pessoais e senha, serão totalmente removidos e você perderá definitivamente acesso à TODOS os seus aplicativos.
        </v-card-title>
        <v-card-actions class="my-8">
          <v-spacer></v-spacer>
          <v-btn class="mx-4 white--text" color="red" @click="remove">EXCLUIR</v-btn>
          <v-btn class="mx-4" @click="back">Cancelar</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
    </v-card>
    </v-layout>
  </v-container>
</template>

<script>
  import ajax from '@/js/base/ajax'
  import auth from '@/js/base/auth'

  export default {
    data: () => ({
    }),

    methods: {
      back () {
        this.$router.back()
      },

      async remove () {
        if (await this.$confirm(
              '<BR>Você perderá acesso a TODOS os seus aplicativos. Deseja prosseguir ?',
              { title: 'Excluir conta' }
        )) {
          var that = this
          ajax.request(
            'ajax.php',
            { name: 'delete', service: 'delaccount' },
            (response) => {
              if (response.data.confirmado) {
                auth.logout()
                that.$store.commit('SET_USER', null)
                that.$store.commit('SET_TOKEN', null)
                that.$router.push('/home')
              }
            }
          )
        }
      }
    }
  }
</script>

<style scoped>
.my-title {
  word-break: normal;
  text-align: justify;
}
</style>