import firebase from 'firebase/app'
import firebaseui from 'firebaseui'
import ajax from '@/js/base/ajax'
import store from '@/store'


const config = {
  apiKey: 'AIzaSyCneK3PnuIgixRThsCt3b87l1pLkIiI-zI',
  authDomain: 'inumero-1470681756920.firebaseapp.com',
  databaseURL: 'https://inumero-1470681756920.firebaseio.com',
  projectId: 'inumero-1470681756920',
  storageBucket: 'inumero-1470681756920.appspot.com',
  messagingSenderId: '794202377879'
}

firebase.initializeApp(config)
// firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
// firebase.auth().languageCode = 'pt-BR'
// firebase.auth().useDeviceLanguage()

// // Is there an email link sign-in?
// if (ui.isPendingRedirect()) {
//   ui.start('#firebaseui-auth-container', uiConfig);
// }
// // This can also be done via:
// if ((firebase.auth().isSignInWithEmailLink(window.location.href)) {
//   ui.start('#firebaseui-auth-container', uiConfig);
// }

const auth = {
  attachProc: null,
  vm: null,
  ui: null,
  uiConfig: {
    signInSuccessUrl: store.state.signInSuccessUrl,
    'callbacks': {
      'signInSuccessWithAuthResult': function () { return false }
    },
    'signInFlow': 'popup',
    signInOptions: [
      {
        provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        scopes: ['profile email'],
        customParameters: { prompt: 'select_account consent' }
      }
      // {
      //   provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
      //   scopes: ['public_profile', 'email']
      //   // customParameters: { auth_type: 'reauthenticate' }
      // }
      // email funciona no cadastro, mas nao no vinculo
      // aceita qq email mesmo que não exista
      // firebase.auth.EmailAuthProvider.PROVIDER_ID
    ]
  },

  init (vm) {
    this.vm = vm
    this.ui = new firebaseui.auth.AuthUI(firebase.auth())
    // this.ui.disableAutoSignIn()

    firebase.auth().onAuthStateChanged((user) => {
      // let requireAuth = vm.$route.matched.some(record => ((record.meta.requireAuth === undefined) || record.meta.requireAuth))
      // if (requireAuth && !user) 
        // vm.$router.push('/login')
      if (this.attachProc)
        this.attachComplete(user)
      else
        this.loginComplete(user, this.vm)
    })
  },

  loginComplete (user, vm) {
    if (user) {
      let userinfo = {
        userid: user.userid,
        displayName: user.displayName,
        email: user.email,
        photoURL: user.photoURL
      }
      if (!userinfo.photoURL) {
        for (let i = 0; i < user.providerData.length; ++i) {
          let pd = user.providerData[i]
          if (pd.photoURL) {
            userinfo.photoURL = pd.photoURL
            break
          }
        }
      }
      vm.$store.commit('SET_USER', userinfo)
      vm.$store.commit('SET_TOKEN', null)
      user.getIdToken(true).then(function (idToken) {
        vm.$store.commit('SET_TOKEN', idToken)
        auth.request(null, null)
      })
    } // else {
    //   vm.$store.commit('RESET')
    // }
  },

  attachComplete (user) {
    if (user) {
      var attachProc = this.attachProc
      user.getIdToken(true).then(function (idToken) {
        attachProc(idToken)
        attachProc = null
      })
    }
  },

  request (username, password, callback = () => {}) {
    let vm = auth.vm
    vm.$store.commit('SET_LOGIN', false)
    ajax.request(
      'login.php',
      { username: username, password: password },
      response => {
        var data = response.data
        if (typeof (data) === 'string') {
          this.logout()
          vm.$router.push('/home')
        } else {
          if (data.user) {
            if (data.user.photoURL)
              data.user.photoURL = vm.$store.state.portalURI + data.user.photoURL
            vm.$store.commit('SET_USER', data.user)
            vm.$store.commit('SET_LOGIN', true)
          }
          vm.$store.commit('SET_INSTANCES', data.instances)
          if (data.groups)
            vm.$store.commit('SET_GROUPS', data.groups)
          if (data.routes)
            vm.$store.commit('SET_ROUTES', data.routes)
          if (data.token) {
            vm.$store.commit('SET_TOKEN', data.token)
            if (auth.uiConfig.signInSuccessUrl) {
              vm.$router.push(auth.uiConfig.signInSuccessUrl)
            }
            if (data.first_access && vm.$store.state.instance !== null) {
              vm.$store.commit('CAPTION_ERROR', 'IMPORTANTE')
              vm.$store.commit('ADD_ERROR', 
                'Seja bem vindo! <br/>Você pode cadastrar uma senha para agilizar o acesso ao sistema.' +
                '<br/>Também é interessante vincular suas outras contas de rede sociais a este usuário.' +
                '<br/><a href="#" id="goPerfil">Clique aqui</a> para fazer agora ou entre depois no PERFIL do usuário.'
                // + '<br/>Você não receberá novamente este aviso.'
              )
              setTimeout(function () {
                let el = document.getElementById('goPerfil')
                el.addEventListener('click', function(event) {
                  event.preventDefault()
                  vm.$router.push('/perfil')
                  vm.$store.commit('DEL_ERROR')
                })
              }, 1000)
            }
          }
        }
        callback(data)
      },
      null,
      null,
      vm.$store.state.portalURI, // requisicao ao Portal
      false                      // nao envia Instance
    )
  },

  loginByUsername (username, password, callback) {
    let vm = auth.vm
    vm.$store.commit('SET_USER', null)
    vm.$store.commit('SET_TOKEN', 'undefined')
    auth.request(username, password, callback)
  },

  authForm (container) {
    firebase.auth().signOut()
    this.ui.start(container, this.uiConfig)
  },

  user () {
    return this.vm ? firebase.auth().currentUser : null
  },

  logout () {
    firebase.auth().signOut()
    this.vm.$router.push('/login')
    this.vm.$store.commit('RESET')
  }
}

export default auth
