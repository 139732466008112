const urlParser = document.createElement('a')


export function domain (url) {
  urlParser.href = url
  return urlParser.hostname
}

export function count (arr) {
  return arr.length
}

export function pretty (value, field, item, fix = true) {
  if (value === null) {
    return ''
  }

  let prefix = (field.prefix || '') + ' '
  if (field.prefixJoin) {
    prefix = item[field.prefixJoin] + ' '
  }

  if (field.join) {
    value = String(value)
    let temp = item[field.value + '__text']
    if (temp) {
      value += ' | ' + temp
    }
  } else if (field.type === 'bool') {
    value = (value === true || parseInt(value || 0) > 0 || value === 'SIM' ? true : false)
  } else if (field.type === 'date') {
    value = prettyDate(value)
  } else if (field.type === 'datetime') {
    value = prettyDatetime(value)
  } else if (field.type === 'float') {
    value = prettyDecimal(value, field.decimal, prefix, field.fixDecimal)
  } else if (typeof (value) === 'object') {
    value = value.join(' | ')
  } else if (field.items && !field.multiple) {
    for (let item of field.items) {
      if (value == item.value) {
        value = item.text
      }
    }
  } else if (fix) {
    value = prefix + value + (field.suffix || '')
  }
  return value
}

export function join (val, options) {
  let result = val
  for (let option of options) {
    if (option.value === val) {
      result = val + ' | ' + option.text
      break
    }
  }
  return result
}

export function zeroPad (value, length) {
  return (Array(length + 1).join('0') + value).slice(-length)
}

export function prettyBool (val) {
  return (parseInt(val) || val === true || 0) ? '√' : '' // '✓'
}

/*
import { dateFormat } from '@/js/base/dateformat'

export function prettyDate (date) {
  if (!date) {
    return ''
  }
  let dt = typeof(date) === 'object' ? date : new Date(date)
  if (dt.toString() === 'Invalid Date' || isNaN(dt)) {
    return String(date)
  }
  return dateFormat(dt, 'dd/mm/yyyy')
}

export function prettyDatetime (date) {
  if (!date) {
    return ''
  }
  let dt = typeof(date) === 'object' ? date : new Date(date)
  if (dt.toString() === 'Invalid Date' || isNaN(dt)) {
    return String(date)
  }
  return dateFormat(dt, 'dd/mm/yyyy HH:MM:ss')
}
*/

export function prettyDate (date) {
  if (!date) {
    return null
  }
  let a = date
  if (typeof(date) !== 'object') {
    // verifica se data já está formatada
    let matches = date.match(/(\d{2})[/](\d{2})[/](\d{4})/gm)
    if (matches) {
      return date
    }
    // new Date precisa das horas para criar data corretamente
    if (a.length == 10) {
      a += ' 00:00:00'
    }
    a = new Date(a)
  }
  if (a.toString() === 'Invalid Date') {
    return date
  }
  let year = a.getFullYear()
  if (String(year).length > 4) {
    return date
  }
  let month = zeroPad(a.getMonth() + 1, 2)
  let day = zeroPad(a.getDate(), 2)
  return day + '/' + month + '/' + year
}

export function prettyDatetime (date) {
  if (!date) {
    return null
  }
  let a = date
  if (typeof(date) !== 'object') {
    // verifica se data já está formatada
    let matches = date.match(/(\d{2})[/](\d{2})[/](\d{4})/gm)
    if (matches) {
      return date
    }
    // new Date precisa das horas para criar data corretamente
    if (a.length == 10) {
      a += ' 00:00:00'
    }
    a = new Date(a)
  }
  if (a.toString() === 'Invalid Date') {
    return date
  }
  let year = a.getFullYear()
  if (String(year).length > 4) {
    return date
  }
  let month = zeroPad(a.getMonth() + 1, 2)
  let day = zeroPad(a.getDate(), 2)
  let hour = zeroPad(a.getHours(), 2)
  let minute = zeroPad(a.getMinutes(), 2)
  // let second = zeroPad(a.getSeconds(), 2)
  // colocar os segundos levanta erro v-datetime-picker 
  // vide /vda/nfe
  return day + '/' + month + '/' + year + ' ' + hour + ':' + minute // + ':' + second
}

export function pluralize (time, label) {
  if (time === 1)
    return time + label
  return time + label + 's'
}

export function capitalize (text) {
  text = text.toLowerCase()
  text = text[0].toUpperCase() + text.substr(1)
  return text
}

export function formatFloat (number, places = 2, prefix = '', thousand = '.', decimal = ',') {
  if (typeof (number) === 'string' && number.indexOf(',') >= 0) {
    if (!number.startsWith(prefix))
      number = prefix + number
    return number
  }
  number = parseFloat(number || 0).toFixed(places)
  let negative = number < 0 ? '-' : ''
  number = String(number)
  let parts = String(Math.abs(number)).split('.')
  let left = String(parts[0])
  if (left === 'NaN')
    return prefix + String(number).trim()
  let right = String(parts[1] || 0).padEnd(places, '0').substr(0, places)
  if (parseInt(left) == 0 && parseInt(right) == 0)
    negative = ''
  let result = ''
  while (left !== '') {
    result = left.substr(-3) + result
    left = left.substr(0, left.length - 3)
    if (left)
      result = thousand + result
  }
  return prefix + negative + result + decimal + right
}

export function prettyDecimal (value, decimal, prefix, fix=true) {
  if (fix) {
    value = formatFloat(value, decimal, prefix)
  // } else if (Math.abs(parseFloat(value) - parseInt(value)) > 0) {
  //   value = formatFloat(value, decimal, prefix)
  } else {
    value = prefix + parseFloat(value)
  }
  return value
}
