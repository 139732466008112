<template>
  <div class="mb-10">
    <my-crud
      caption="Usuários"
      singular="Seu perfil"
      :creatable="false"
      :editable="true"
      :deletable="false"
      :searchable="false"
      :titlable="true"
      :navigable="false"
      :showGrid="false"
      :attach="false"
      @loaded="emptyPwd"
    >
      <template v-slot>
        <my-crud
          ref="conta"
          name="conta"
          :current="getCurrent()"
          caption="Contas vinculadas"
          singular="Conta"
          :creatable="true"
          :editable="true"
          :deletable="true"
          :searchable="false"
          :titlable="true"
          :remoteConfig="false"
          :attach="false"
          :showUnique="false"
          :iconFavorite="false"
        />
      </template>
    </my-crud>

    <v-btn 
      color="red" small dark 
      class="mx-10 my-5"
      @click="delaccount"
    >
      Excluir minha conta definitivamente
    </v-btn>

    <v-dialog width="350" v-model="vinculo">
      <v-card class="py-10 pr-6">
        <div id="firebaseui-auth-container"></div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import ajax from '@/js/base/ajax'
  import auth from '@/js/base/auth'

  export default {
    data: () => ({
      expanded: 0,
      vinculo: false,

      main: {
        fields: {
          id: { text: 'ID', class2: 'my-realce' },
          nome: { required: true },
          senha: {
            type: 'password',
            hint: 'Digite nova senha para alterar anterior',
            readonly: true,
            rules: [function (v) {
              v = (v || '').trim()
              let result = true
              // pelo menos uma minuscula + 1 maiúscula + caracter especial e 6 carac sem repeticao
              // let regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[$*&@#])(?:([0-9a-zA-Z$*&@#])(?!\1)){6,}$/;
              let regex = /^(?:(.)(?!\1)){1,}$/;
              if (regex.test(v)) {
                // verifica tres caracteres sequenciais
                for (let i = 0; i < v.length-2; i++) {
                  if (v[i].charCodeAt() == (v[i+1].charCodeAt() - 1) && v[i].charCodeAt() == (v[i+2].charCodeAt() - 2)) {
                    result = 'Sequência não permitida'
                    break
                  }
                }
                if (result === true && v.length < 6) {
                  result = 'Mínimo 6 caracteres'
                }
              } else {
                result = 'Caracter repetido'
              }
              return result
            }]
          },
          resenha: {
            text: 'Repita a senha',
            readonly: true,
            type: 'password',
            rules: [function (v, item) {
              v = (v || '').trim()
              return (v === '' &&  !item['senha']) || v === item['senha'] || 'Confirmação incorreta'
            }]
          },
          email: { 
            text: 'E-mail',
            rules: [(v) => v === '' ? true : (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(v) ? true : 'Email inválido')],
            readonly: true
          },
          telefone: { mask: '(##) #####-####' },
          criadoEm: { text: 'Criado em', readonly: true },
          grupos: {
            text: 'Permissão de acesso',
            items: [
              { value: 'adm', text: 'Admin' },
              { value: 'spt', text: 'Suporte' },
              { value: 'usr', text: 'Usuário' }
            ],
            multiple: true,
            readonly: true,
            class: 'xs12 md6'
          },
          foto: {
            type: 'img',
            resize: [140, 140],
            hint: 'Foto utilizada quando login realizado com Usuário/Senha',
            class: 'xs12 sm6 md4'
          }
        },
        sorted: true // ['id', ] true=ordena pelo ordem de fields
      },

      conta: {
        fields: {
          foto: {
            editable: false,
            html: function (value) {
              return '<img width="40" src="' + value + '">'
            }
          },
          tipo: {
            text: 'Provedor',
            readonly: true,
            html: function (value, item, field) {
              for (let f of field.items) {
                if (f.value === value) {
                  value = f.text
                  break
                }
              }
              return value
            },
            items: [
              { value: 'G', text: 'Google' },
              { value: 'F', text: 'Facebook' },
              { value: 'E', text: 'Email' }
            ],
            multiple: false
          },
          nome: { readonly: true },
          email: { editable: false },
          telefone: { editable: false },
          criadoEm: { editable: false },
          ultimoLogin: { editable: false },
          bloqueado: {
            rules: [function (v, item, field, vm) {
              let result = true
              if (v) {
                result = 'Pelo menos uma conta deve permanecer ativa!'
                for (let record of vm.records) {
                  if (!record.bloqueado) {
                    result = true
                    break
                  }
                }
              }
              return result
            }]
          }
        },
        sorted: true
      }
    }),

    watch: {
      vinculo (val) {
        if (!val) {
          auth.attachProc = null
        }
      }
    },

    methods: {
      onBeforeDelete (vm) {
        if (vm.name === 'conta') {
          if (vm.records.length <= 1) {
            this.$store.commit('ADD_ERROR', 'Conta única. Não pode ser excluída!')
            return true
          }
        }
      },
      onBeforeSave (vm) {
        for (let idx in vm.records) {
          vm.records[idx]['resenha'] = ''
          vm.records[idx].__original__['resenha'] = ''
        }
      },
      onAfterSave (vm) {
        if (vm.name === 'main') {
          if (this.$store.state.loginUsername) {
            let userinfo = {
              displayName: vm.editedItem.nome,
              email: vm.editedItem.email,
              photoURL: vm.$store.state.serverURI + vm.editedItem.foto__filename
            }
            this.$store.commit('SET_USER', userinfo)
          }
        }
      },
      onPrepareCreate (vm) {
        if (vm.name === 'conta') {
          this.vinculo = true
          auth.attachProc = this.attached
          var that = this
          setTimeout(function() {
            auth.authForm('#firebaseui-auth-container')
            that.translateFirebase()
          }, 500)
          return true
        }
      },
      getCurrent () {
        return this
      },
      translateFirebase () {
        let e = document.getElementsByClassName('firebaseui-idp-text-long')
        if (e.length > 0) {
          for (let i = 0; i < e.length; i++) {
            if (e[i].textContent === 'Sign in with Facebook') e[i].textContent = 'Vincular conta Facebook'
            else if (e[i].textContent === 'Sign in with Google') e[i].textContent = 'Vincular conta Google'
            else if (e[i].textContent === 'Sign in with email') e[i].textContent = 'Vincular conta de Email'
          }
        } else {
          setTimeout(this.translateFirebase, 500)
        }
      },
      attached (idToken) {
        this.vinculo = false
        var that = this
        ajax.request('account.php', { value: idToken },
          response => {
            if (response.data.alert) this.$store.commit('ADD_ERROR', response.data.alert)
            else that.$refs.conta.cancel()
          }
        )
      },
      emptyPwd (vm) {
        if (!vm.main) {
          setTimeout(function () {
            vm.fields.senha.readonly = false
            vm.fields.resenha.readonly = false
            vm.fields.email.readonly = false
          }, 2000)
        }
      },
      voltar () {
        this.$router.go(-1)
      },
      async delaccount () {
        if (await this.$confirm(
              '<BR>Você perderá acesso a TODOS os seus aplicativos. Deseja prosseguir ?',
              { title: 'Excluir conta' }
        )) {
          this.$router.push('/delaccount')
        }
      }
    }
  }
</script>

<style scoped>
  .my-data-table {
    margin: 10px 20px 20px 20px;
    padding: 10px;
  }
</style>
