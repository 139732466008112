<template>
  <div>
    <my-crud
      ref="suporte"
      caption="Chamados de suporte"
      singular="Chamado de suporte"
      captionAdd="Novo chamado"
      :deletable="false"
      :showUnique="false"
      :iconFavorite="false"
			:showAutoSave="false"
    >
    </my-crud>
  </div>
</template>

<script>
  export default {
    data: () => ({
      main: {
        fields: {
					id: {},
					emissao: { text: 'Data abertura', disable: true, readonly: true },
					finalizado: { text: 'Data encerramento', disable: true, readonly: true },
					assunto: {
						items: [
							{ value: '1', text: 'Vendas' },
							{ value: '2', text: 'Financeiro' },
							{ value: '3', text: 'Suporte técnico' },
							{ value: '4', text: 'Suporte DataStudio' },
							{ value: '5', text: 'Suporte PowerBI' }
						],
						rules: [(v) => !!v || 'Requerido']
					},
					pergunta: { text: 'Detalhes sobre o assunto ', class: 'xs12', rows: 10 },
					resposta: { text: 'Retorno do atendimento', class: 'xs12', rows: 10, disable: true }
        },
				sorted: true
      }
    }),

    methods: {
      onAfterCreate (vm) {
        if (vm.name == 'main') {
          vm.fields.assunto.disable = false
          vm.fields.pergunta.disable = false
        }
      },

      onAfterNav (vm) {
        if (vm.name == 'main') {
          vm.fields.assunto.disable = true
          vm.fields.pergunta.disable = true
        }
      },

      onAfterSave (vm) {
        this.onAfterNav(vm)
      }
    }
  }
</script>
