<template>
  <v-app id="inspire" class="my-app">
    <v-app-bar app color="white" height="80" style="z-index: 9;">
      <v-menu v-if="user != null" offset-y>
        <template v-slot:activator="{ on }">
          <v-app-bar-nav-icon v-on="on"></v-app-bar-nav-icon>
        </template>
        <v-list>
          <v-list-item to="/home"><v-list-item-title>Início</v-list-item-title></v-list-item>
          <v-list-item to="/perfil"><v-list-item-title>Meu Perfil</v-list-item-title></v-list-item>
          <v-list-item to="/apps"><v-list-item-title>Meus Apps</v-list-item-title></v-list-item>
          <v-list-item to="/atend"><v-list-item-title>Atendimento</v-list-item-title></v-list-item>
          <v-divider/>
          <v-list-item v-if="myGrant('/aplicativo')" to="/aplicativo"><v-list-item-title>Aplicativos</v-list-item-title></v-list-item>
          <v-list-item v-if="myGrant('/instancia')" to="/instancia"><v-list-item-title>Instâncias</v-list-item-title></v-list-item>
          <v-list-item v-if="myGrant('/usuario')" to="/usuario"><v-list-item-title>Usuários</v-list-item-title></v-list-item>
          <v-list-item @click="logout()"><v-list-item-title>Sair</v-list-item-title></v-list-item>
        </v-list>
      </v-menu>
      <v-avatar class="mr-3" size="70"> <!-- color="grey lighten-5" -->
        <v-img contain max-height="70%" src="img/inumero-150x100.png"></v-img>
      </v-avatar>
      <v-toolbar-title class="font-weight-black headline">  <!--  d-none d-md-flex -->
        INÚMERO
      </v-toolbar-title>
      <v-layout align-center justify-end>
        <div class="d-none d-md-flex">
          <div v-if="isPage('/') || isPage('/home')">
            <v-btn class="mr-4" outlined to="/apps">Meus Apps</v-btn>
            <v-btn v-if="!standalone" class="mr-4" outlined @click="$vuetify.goTo('#plans'); posAtual=6;" >Planos</v-btn>
          </div>
          <v-btn v-else-if="!(isPage('/apps') || isPage('/login'))" class="mr-4" outlined to="/apps">Meus Apps</v-btn>
        </div>
        <!--
            Usuário logado
        -->
        <div v-if="user != null">
          <!--
          <v-badge v-if="messages > 0" overlap class="mr-4">
            <span slot="badge">{{ messages }}</span>
            <v-avatar>
              <v-btn icon to="/atend">
                <v-icon>mdi-face-agent</v-icon>
              </v-btn>
            </v-avatar>
          </v-badge>
          <v-btn v-else icon class="mr-4" to="/atend">
            <v-icon>mdi-face-agent</v-icon>
          </v-btn>
          -->
          <!--
              Informação do usuário
          -->
          <v-menu nudge-bottom=10 offset-y left>
            <template v-slot:activator="{ on }">
              <v-btn text v-on="on">
                <v-avatar>
                  <img v-if="user.photoURL" v-bind:src="user.photoURL" alt="">
                  <v-icon v-else>mdi-account</v-icon>
                </v-avatar>
                <span class="firstname">{{ firstName() }}</span>
              </v-btn>
            </template>
            <v-card>
              <v-card color="#82b1ff" class="userAvatar">
                <!--<v-img height="200px" v-bind:src="user.photoURL" v-bind:alt="user.displayName"> </v-img>-->
                <v-layout align-center justify-center fill-height column>
                  <v-avatar size="100">
                    <img v-if="user.photoURL" v-bind:src="user.photoURL" alt="">
                    <v-icon v-else>mdi-account</v-icon>
                  </v-avatar>
                  <div class="userName">{{ user.displayName }}</div>
                  <div class="userEmail">{{ user.email }} </div>
                </v-layout>
              </v-card>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text to="/perfil">Perfil</v-btn>
                <v-btn color="primary" text @click="logout()">Sair</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-menu>
        </div>
        <!-- Fim Usuário Logado -->
        <v-btn v-else-if="!isPage('/login')" raised dark @click="login">
          <v-icon>mdi-login</v-icon>
          <span>ENTRAR</span>
        </v-btn>
        <v-btn v-if="!(isPage('/') || isPage('/home'))" dark @click="back" class="ml-1" style="min-width: 24px; padding: 0 4px;">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </v-layout>
    </v-app-bar>

    <v-content>
      <keep-alive v-if="user != null">
        <router-view></router-view>
      </keep-alive>
      <!-- reset cache das paginas -->
      <router-view v-else></router-view>
    </v-content>

    <v-btn fixed bottom right color="primary"
        v-if="isPage('/') || isPage('/home')"
        icon
        @click="openWhatsapp"
    >
      <v-img src="img/whatsapp.png"></v-img>
    </v-btn>

    <!-- <div v-if="isMobile()">
      <v-btn fixed bottom right color="primary"
        v-if="isPage('/') || isPage('/home')"
        x-large icon
        @click="openWhatsapp"
      >
        <v-img src="img/whatsapp.png"></v-img>
      </v-btn>
    </div>
    <div v-else>
      <v-btn fixed bottom right fab x-small
        v-if="isPage('/') || isPage('/home')"
        style="bottom: 256px; right: 0; z-index: 9;"
        @click="expanded=!expanded"
      >
        <v-icon v-if="expanded">mdi-chevron-right</v-icon>
        <v-icon v-else>mdi-chevron-left</v-icon>
      </v-btn>

      <v-btn fixed bottom right color="primary"
        v-if="isPage('/') || isPage('/home')"
        :x-large="expanded" :fab="!expanded" :small="!expanded"
        style="bottom: 200px; right: 0; z-index: 9; border-radius: 28px 0 0 28px;"
        @click="openWhatsapp"
      >
        <v-img src="img/whatsapp.png" style="left: -20px;"></v-img>
        <div v-if="expanded" style="font-size: 12px;">
          <span>Estamos no Whatsapp</span><br/>
          <div style="display: inline-flex;">41 <h2>3344-5444</h2></div>
        </div>
      </v-btn>

      <v-btn fixed bottom right
        v-if="isPage('/') || isPage('/home')"
        :x-large="expanded" :fab="!expanded" :small="!expanded"
        style="bottom: 146px; right: 0; z-index: 9; border-radius: 32px 0 0 32px;"
        @click="canalYoutube"
      >
        <v-img src="img/youtube.png" :style="expanded ? 'left:-22px;' : 'left:-14px;'"></v-img>
        <div v-if="expanded" style="font-size: 12px;">Canal Youtube</div>
      </v-btn>

      <v-btn fixed bottom right
        v-if="(!standalone) && (isPage('/') || isPage('/home'))"
        :x-large="expanded" :fab="!expanded" :small="!expanded"
        style="bottom: 92px; right: 0; z-index: 9; border-radius: 32px 0 0 32px;"
        @click="$vuetify.goTo('#contact'); posAtual=7;"
      >
        <v-icon :style="expanded ? 'left:-10px;' : ''">mdi-email</v-icon>
        <div v-if="expanded" style="font-size: 12px;">Fale conosco</div>
      </v-btn>
    </div> -->

    <v-footer class="justify-center" color="#292929">
      <div class="title font-weight-light grey--text text--lighten-1 text-center">
        <v-btn dark text class="mr-5" to="/contrato">
          <span class="grey--text text--lighten-1">Contrato de uso</span>
        </v-btn>
        <v-btn dark text class="mr-5" to="/politica">
          <span class="grey--text text--lighten-1">Política de privacidade</span>
        </v-btn>
        <span>
          <div>&copy; {{ (new Date()).getFullYear() }} INÚMERO v{{ version }}</div>
        </span>
      </div>
    </v-footer>

    <!--
      Alerts
    -->
    <v-snackbar v-model="showAlert" top vertical multi-line :timeout="5000">
      <div
        v-for="(item, index) in alerts"
        :key="'alr'+String(index)"
        class="text-center pb-2"
      >{{ item }}</div>
    </v-snackbar>

    <!--
      Errors
    -->
    <v-dialog v-model="showError" persistent width="70%" transition="">
      <v-card>
        <v-card-title class="red white--text" style="font-size: 1rem;">
          <v-icon color="white" class="mr-2">mdi-alert-circle-outline</v-icon>
          <span> {{ captionError }} </span>
        </v-card-title>
        <div
          v-for="(item, index) in errors" 
          :key="'err'+String(index)"
          class="my-errors"
        >
          <span v-html="item"></span>
        </div>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="delError()">Fechar</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
  import auth from '@/js/base/auth'
  import { hasGrant, isMobile } from '@/js/base/misc'  // isMobileOrTablet
  import { mapState } from 'vuex'

  export default {

    data: () => ({
      version: '1.3.2',
      messages: 0,
      showError: false,
      showAlert: false,
      expanded: !isMobile()
    }),

    computed: {
      ...mapState(['user', 'alerts', 'captionError', 'errors', 'groups', 'favorites', 'recent', 'currentTab', 'standalone'])
    },

    /*
    created() {
      this.standalone = window.navigator.standalone || window.matchMedia('(display-mode: standalone)').matches ||
            ((screen.height-document.documentElement.clientHeight) <= 70)
          || document.referrer.startsWith('android-app://')
      if (isMobileOrTablet() && !this.standalone) {
        var that = this
        window.navigator.getInstalledRelatedApps().then(relatedApps => {
          for (let app of relatedApps) {
            if (!app.nothing) //app.id + '|' + app.name + '|' + app.url + ' - ')
              that.standalone = true
          }
        })
      }
    },
    */

    watch: {
      errors () {
        this.showError = this.errors.length > 0
      },
      alerts () {
        this.showAlert = this.alerts.length > 0
      },
      showAlert (val) {
        if (!val) this.delAlert()
      }
    },

    methods: {
      back () {
        this.$router.back()
      },
      delError () {
        auth.vm.$store.commit('DEL_ERROR')
      },
      delAlert () {
        auth.vm.$store.commit('DEL_ALERT')
      },
      callingAjax () {
        return this.$store.state.callingAjax > 0
      },
      logout () {
        auth.logout()
        this.$store.commit('SET_USER', null)
        this.$store.commit('SET_TOKEN', null)
        this.home()
      },
      login () {
        this.$router.push('/login')
      },
      home () {
        this.$router.push('/home')
      },
      isPage (url) {
        return (window.location.pathname || '').endsWith(url)
      },
      firstName () {
        let name = ''
        if (this.user && this.user.displayName) name = 'Olá ' + this.user.displayName.split(' ')[0]
        return name
      },
      myGrant (url) {
        return hasGrant(url)
      },
      openWhatsapp () {
        let url = '.whatsapp.com/send?phone=554133445444&text=Olá, meu chamo ..... e preciso de ajuda para .....'
        url = 'https://' + (isMobile() ? 'api' : 'web') + url
        window.open(url, '_blank')
      },
      canalYoutube () {
        window.open('https://www.youtube.com/channel/UCBCJpzV38CCUO0lN2gZGp3w/videos?view=0&sort=da', '_blank')
      },
      isMobile () {
        return isMobile()
      }
    }
  }
</script>

<style scoped>
  .v-input--switch {
    margin: 0;
  }
  .v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
  }
  @media (max-width: 500px) {
    .firstname {display: none;}
  }
  @media (max-width: 440px) {
    .logo {display: none;}
  }
  .container {
    padding: 0 10px 0 25px;
  }
  .v-text-field {
    padding-top: 0;
  }
  .firstname {
    text-transform: capitalize;
    font: unset;
    padding-left: 3px;
  }
  .userAvatar {
    margin: 2px;
    padding: 20px 20px 5px 20px;
  }
  .userName {
    font-weight: bold;
    margin-top: 5px;
  }
  .userEmail {
    font-size: smaller;
    margin-bottom: 5px;
    font-style: italic;
  }
  .v-alert {
    padding: 8px;
    color: black;
  }
  .xdebug-error {
    color: black;
  }
  .callingAjax {
    margin: 0;
  }
  .set-color {
    max-width: 40px;
  }
  .my-errors {
    font-size: 0.9rem !important;
    padding: 5px 20px;
    margin: 20px 3%;
    width: 94%;
  }
  .my-footer {
    width: inherit;
    border-top: 1px solid;
    padding: 1px 16px;
  }
  .margin-footer {
    margin: 0 10px 0 4px;
  }
  .my-left-menu {
    position: fixed;
    z-index: 9;
  }
  .my-aparencia {
    position: fixed;
    z-index: 10;
  }
  .my-tabs {
    border-radius: 3px;
  }
  .no-underline {
    text-decoration: none;
    height: 100%;
    display: table;    
  }
  .my-valign {
    vertical-align: middle;
    display: table-cell;
  }
</style>

<style>
  .my-app {
    font-size: 0.8rem !important;
  }
  .my-expansion {
    margin: 0;
    background-color: inherit !important;
  }
  .v-input {
    font-size: 0.8rem !important;
  }
  .v-expansion-panel-content__wrap {
    padding: 0 !important;
    margin-bottom: 20px !important;
  }
  .my-border {
    border-radius: 3px;
  }
  .theme--light.v-select .v-chip--disabled {
    color: black !important;
  }
  .v-badge__badge {
    top: 2px !important;
  }
  .v-select__slot {
    flex-wrap: wrap;
    min-width: 70px;
  }
  .my-realce {
    font-weight: bold;
    background-color: aliceblue;
    border-radius: 8px;
  }
  .my-realce .v-text-field__slot {
    font-size: x-large;
  }
  .v-textarea textarea {
    line-height: 1.2;
    margin-top: 4px;
  }
</style>
