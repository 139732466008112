<template>
  <div id="notFound" class="text-center col-sm-12">
    <h1>Você está perdido</h1>
    <h4>Este endereço não existe</h4>
    <router-link to="/" class="vertical-5p lead">Ir para o Início</router-link>
  </div>
</template>

<script>
  export default {
    name: 'NotFound'
  }
</script>

<style>
#notFound {
  padding: 10em;
}
</style>
