import ajax from '@/js/base/ajax'

function changedTab (state, pos, force = false) {
  if (force || state.currentTab != pos) {
    if (pos >= state.recent.length)
      pos = state.recent.length - 1
    state.currentTab = pos
  }
}

function resetUser (state) {
  state.callingAjax = 0
  state.user = null
  state.token = null
  state.loginUsername = false
  state.groups = []
  state.routes = []
  state.joinLink = ''
  state.favorites = []
  state.recent = []
  sessionStorage.removeItem('user')
  sessionStorage.removeItem('token')
  sessionStorage.removeItem('groups')
  sessionStorage.removeItem('routes')
  sessionStorage.removeItem('loginuser')
}

export default {
  SET_STANDALONE (state, value) {
    state.standalone = value
  },
  INC_AJAX (state) {
    state.callingAjax += 1
  },
  DEC_AJAX (state) {
    state.callingAjax -= 1
  },
  RESTART_AJAX (state) {
    state.callingAjax = 0
  },
  SET_INSTANCE (state, value) {
    state.instance = value ? -1 : -1 // portal sempre -1
  },
  LOAD_INSTANCE (state) {
    state.instance = -1
  },
  SET_INSTANCES (state, value) {
    state.instances = value
  },
  SET_USER (state, user) {
    state.user = user
    if (user)
      sessionStorage.setItem('user', JSON.stringify(user))
    else
      resetUser(state)
  },
  SET_TOKEN (state, value) {
    state.token = value
    sessionStorage.setItem('token', JSON.stringify(value))
  },
  SET_LOGIN (state, value) {
    state.loginUsername = value
    sessionStorage.setItem('loginuser', JSON.stringify(value))
  },
  SET_GROUPS (state, value) {
    state.groups = value
    sessionStorage.setItem('groups', JSON.stringify(state.groups))
  },
  SET_ROUTES (state, value) {
    state.routes = value
    sessionStorage.setItem('routes', JSON.stringify(state.routes))
  },
  LOAD_USER (state) {
    let value = JSON.parse(sessionStorage.getItem('token'))
    if (value) {
      state.token = value
      state.user = JSON.parse(sessionStorage.getItem('user'))
      state.loginUsername = JSON.parse(sessionStorage.getItem('loginuser'))
      state.groups = JSON.parse(sessionStorage.getItem('groups')) || []
      state.routes = JSON.parse(sessionStorage.getItem('routes')) || []
    }
  },
  JOIN_LINK (state, value) {
    state.joinLink = value
  },
  FORCE_REFRESH (state) {
    state.forceRefresh = !state.forceRefresh
  },
  ADD_ALERT (state, value) {
    state.alerts.push(value)
  },
  DEL_ALERT (state) {
    state.alerts.splice(0, state.alerts.length)
  },
  CAPTION_ERROR (state, value) {
    state.captionError = value
  },
  ADD_ERROR (state, value) {
    state.errors.push(value)
  },
  DEL_ERROR (state) {
    state.captionError = 'Mensagem de erro'
    state.errors.splice(0, state.errors.length) 
  },
  INIT_FAVORITE (state, item) {
    state.favorites.push(item)
  },
  ADD_FAVORITE (state, item) {
    state.favorites.push(item)
    ajax.request('ajax.php', { name: 'add', service: 'favorite', item: item })
  },
  DEL_FAVORITE (state, index) {
    ajax.request('ajax.php', { name: 'del', service: 'favorite', item: state.favorites[index] })
    state.favorites.splice(index, 1)
  },
  ADD_RECENT (state, value) {
    let pos = null
    if ((value !== '/') && !(value.endsWith('/home') || value.endsWith('/login'))) {
      for (let idx in state.recent) {
        if (state.recent[idx].url == value) {
          pos = idx
          break
        }
      }
      if (!pos) {
        let text = value.split('/')
        text = text[text.length - 1]
        if (text) {
          text = text.replace('_', ' ')
          state.recent.push({ text: text, url: value })
        }
        pos = state.recent.length - 1
      }
    }
    changedTab(state, pos)
  },
  DEL_RECENT (state, pos) {
    state.recent.splice(pos, 1)
    changedTab(state, pos, true)
    if (pos >= state.recent.length)
      pos = state.recent.length - 1
    let recent = state.recent[pos]
    let url = recent ? recent.url : '/'
    state.router.push(url)

  },
  RESET (state) {
    resetUser(state)
  }
}
