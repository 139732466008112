<template>
  <v-container fluid fill-height class="py-4">
    <div v-if="forceRefresh" />
    <v-layout flex align-center justify-center>
      <v-card max-width="300">
        <v-toolbar dark>
          <v-toolbar-title class="white--text text-center"><h4>ENTRAR</h4></v-toolbar-title>
        </v-toolbar>
        <v-layout align-center justify-center column>
          <div id="firebaseui-auth-container" class="mt-4 "></div>
          <h4 class="px-4 red--text text-center">
            <div>Não tem cadastro ou esqueceu a senha,</div>
            <div>clique no botão acima</div>
          </h4>
          <div class="ma-4">
            <v-form v-model="valid" ref="form">
              <v-text-field
                placeholder="Seu nº de usuário"
                v-model="username"
                :rules="[usernameRules]"
                prepend-icon="mdi-account"
                class="digite"
                @keyup="user_error_clear"
                dense
              ></v-text-field>
              <v-text-field
                ref="password"
                placeholder="Sua senha"
                v-model="password"
                min="8"
                :append-icon="e1 ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="() => (e1 = !e1)"
                :type="e1 ? 'password' : 'text'"
                counter
                prepend-icon="mdi-lock"
                class="digite"
                :rules="passwordRules"
                :error-messages="user_error"
                @keyup="user_error_clear"
                @keyup.enter.stop="submit"
                autocomplete="new-password"
                dense
              ></v-text-field>
              <v-layout align-center justify-center>
                <v-btn light class="my-4" @click="submit" :loading="enviando" :disabled="!valid">Enviar</v-btn>
              </v-layout>
            </v-form>
          </div>
        </v-layout>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
  import auth from '@/js/base/auth'
  import { mapState } from 'vuex'

  export default {
    name: 'auth',

    data () {
      return {
        enviando: false,
        valid: false,
        e1: true,
        password: '',
        passwordRules: [ (v) => !!v || 'Senha obrigatória' ],
        username: '',
        user_error: '',
      }
    },

    computed: {
      ...mapState(['forceRefresh']),
    },

    watch: {
      forceRefresh () {
        if (document.getElementById('firebaseui-auth-container')) {
          auth.authForm('#firebaseui-auth-container')
          setTimeout(this.translateFirebase, 500)
        }
      }
    },

    mounted () {
      let username = localStorage.getItem('username')
      if (username) this.username = username
      auth.authForm('#firebaseui-auth-container')
      setTimeout(this.translateFirebase, 500)
    },

    methods: {
      // isPending () {
      //   return auth.ui && auth.ui.isPendingRedirect()
      // },

      usernameRules (val) {
        let result = true
        val = (val || '').trim()
        if (!val)
          result = 'Obrigatório'
        else if ((!(parseInt(val) > 0)) &&
            (!(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/.test(val))))
          result = 'ID/email inválido'
        return result
      },

      submit () {
        let vm = this
        vm.user_error = ''
        if (this.$refs.form.validate()) {
          vm.enviando = true
          setTimeout(() => { vm.enviando = false }, 3000)

          auth.loginByUsername(
            this.username,
            this.password,
            data => {
              vm.enviando = false
              if (data.user_error) {
                vm.user_error = data.user_error
              } else {
                localStorage.setItem('username', this.username)
                vm.password = ''
              }
            }
          )
        }
      },

      translateFirebase () {
        let e = document.getElementsByClassName('firebaseui-idp-text-long')
        if (e.length > 0) {
          for (let i = 0; i < e.length; i++) {
            if (e[i].textContent === 'Sign in with Facebook') e[i].textContent = 'Use Facebook'
            else if (e[i].textContent === 'Sign in with Google') e[i].textContent = 'Use conta Google'
            else if (e[i].textContent === 'Sign in with email') e[i].textContent = 'Use seu Email'
          }
        } else {
          setTimeout(this.translateFirebase, 500)
        }
      },

      user_error_clear () {
        this.user_error = ''
      }
    }
  }
</script>

<style scoped>
  @import "../../../node_modules/firebaseui/dist/firebaseui.css";
  .my-flex {
    background-color: white;
  }
  .digite {
    margin-bottom:10px;
  }
  .text-center {
    width: 100%;
    text-align: center;
  }
</style>

<style>
  v-application ol, .v-application ul {
    padding-left: 0px;
  }
</style>
