<template>
  <my-crud
    ref="atendimento"
    caption="Atendimento"
    singular="Atendimento"
    :deletable="false"
    :iconFavorite="false"
    :showUnique="false"
    :archive="false"
  >
    <template v-slot="props">
      <v-card>
      <my-crud
        name="resposta"
        :main="props.main"
        :current="getCurrent()"
        caption="Respostas"
        singular="Resposta"
        :titlable="false"
        :creatable="true"
        :deletable="false"
      />
      </v-card>
    </template>
  </my-crud>
</template>

<script>
  import { mapState } from 'vuex'
  import { hasGroup } from '@/js/base/misc'

  export default {
    data: () => ({
      main: {
        fields: {
          id: { gridable: hasGroup('spt'), editable: hasGroup('spt') },
          data: { readonly: true },
          status: {
            text: 'Status',
            default: '1',
            readonly: !hasGroup('spt'),
            class: 'xs12 md4',
            items: [
              { value: '1', text: 'Aguarda resposta do suporte', disabled: false },
              { value: '2', text: 'Aguarda resposta do usuário', disabled: false },
              { value: '3', text: 'Usuário pede urgência', disabled: true },
              { value: '8', text: 'Finalizado pelo usuário', disabled: true },
              { value: '9', text: 'Finalizado pelo suporte', disabled: false }
            ]
          },
          atendente_id: { text: 'Atendente', readonly: true, gridable: hasGroup('spt'), editable: hasGroup('spt') },
          instancia_id: { 
            text: 'Aplicativo',
            rules: [(v) => (parseInt(v)== -1 || parseInt(v)>0 || 'Selecione aplicativo')]
          },
          usuario_id: { text: 'Usuário', readonly: true, gridable: hasGroup('spt'), editable: hasGroup('spt') },
          nome: { gridable: false, editable: hasGroup('spt'), readonly: true },
          email: { gridable: false, editable: hasGroup('spt'), readonly: true },
          tipo: {
            text: 'Departamento',
            class: 'xs12 md2',
            items: [
              { value: '1', text: 'Suporte técnico' },
              { value: '2', text: 'Financeiro' },
              { value: '3', text: 'Alteração de plano' },
              { value: '4', text: 'Outros assuntos' },
              { value: '8', text: 'Cancelamentos' },
              { value: '9', text: 'Ouvidoria' }
            ]
          },
          assunto: { class: 'xs12 md4' },
          pergunta: { text: 'Pergunta', class: 'xs12' },
          nota: { text: 'Nota', gridable: hasGroup('adm'), editable: false }
        },
        sorted: true,
        columns: ['id', 'data', 'status', 'atendente_id', 'instancia_id', 'tipo', 'assunto', 'nota']
      },

      resposta: {
        fields: {
          data: { class: 'xs12 md2', readonly: true },
          resposta: { text: 'Resposta', class: 'xs12 md10' }
        },
        sorted: true,
        columns: ['data', 'resposta']
      }
    }),

    computed: {
      ...mapState(['user'])
    },

    mounted () {
      var that = this
      setInterval(function() { that.refreshNow() }, 30000);
    },

    activated() {
      this.refreshNow()
    },

    methods: {
      getCurrent () {
        return this
      },

      onAfterCreate (vm) {
        if (vm.name == 'main') {
          vm.fields.instancia_id.disable = false
          vm.fields.tipo.disable = false
          vm.fields.assunto.disable = false
          vm.fields.pergunta.disable = false
        }
      },

      onAfterNav (vm) {
        if (vm.name == 'main') {
          vm.fields.instancia_id.disable = true
          vm.fields.tipo.disable = true
          vm.fields.assunto.disable = true
          vm.fields.pergunta.disable = true
        }
      },

      onAfterSave (vm) {
        this.onAfterNav(vm)
      },

      refreshNow () {
        let atend = this.$refs.atendimento
        if (this.user != null && 
            window.location.pathname === '/atend' &&
            atend && !atend.editing) {
          atend.cancel()
        }
      }
    }
  }
</script>    
