import NotFound from '@/components/base/404.vue'
import Login from '@/components/base/Login.vue'
import Home from '@/components/Home.vue'
import Perfil from '@/components/Perfil.vue'
import Apps from '@/components/Apps.vue'
import Atend from '@/components/Atend.vue'
import Aplicativo from '@/components/Aplicativo.vue'
import Instancia from '@/components/Instancia.vue'
import Usuario from '@/components/Usuario.vue'
import Politica from '@/components/Politica.vue'
import Contrato from '@/components/Contrato.vue'
import DelPerfil from '@/components/DelPerfil.vue'
import DataStudio from '@/components/DataStudio.vue'
import Suporte from '@/components/Suporte.vue'

// Routes
const routes = [
  {path: '*', component: NotFound, meta: {requireAuth: false}},
  {path: '/', component: Home, meta: {requireAuth: false}},
  {path: '/home', component: Home, meta: {requireAuth: false}},
  {path: '/login', component: Login, meta: {requireAuth: false}},
  {path: '/perfil', component: Perfil, meta: {requireAuth: true}},
  {path: '/apps', component: Apps, meta: {requireAuth: true}},
  {path: '/atend', component: Atend, meta: {requireAuth: true}},
  {path: '/aplicativo', component: Aplicativo, meta: {requireAuth: true}},
  {path: '/instancia', component: Instancia, meta: {requireAuth: true}},
  {path: '/usuario', component: Usuario, meta: {requireAuth: true}},
  {path: '/politica', component: Politica, meta: {requireAuth: false}},
  {path: '/contrato', component: Contrato, meta: {requireAuth: false}},
  {path: '/delaccount', component: DelPerfil, meta: {requireAuth: true}},
  {path: '/datastudio', component: DataStudio, meta: {requireAuth: false}},
  {path: '/suporte', component: Suporte, meta: {requireAuth: true}}
]

export default routes
