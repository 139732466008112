<template>
  <my-crud
    caption="Usuários"
    singular="Usuário"
    :creatable="true"
    :editable="true"
    :deletable="false"
  >
    <template v-slot:tab="props">
      <v-tab :key="props.main.tabKey(0)">
        Contas ({{ props.main.countChild('conta') }})
      </v-tab>

      <v-tab :key="props.main.tabKey(1)">
        Aplicativos ({{ props.main.countChild('aplicativo') }})
      </v-tab>

      <v-tab :key="props.main.tabKey(2)">
        Instâncias ({{ props.main.countChild('instancia') }})
      </v-tab>

      <v-tab :key="props.main.tabKey(3)">
        Convidado ({{ props.main.countChild('convidado') }})
      </v-tab>
    </template>

    <template v-slot:tab-items="props">
      <v-tab-item :key="props.main.tabKey(0)" :transition="false" eager>
        <my-crud
          ref="conta"
          name="conta"
          :main="props.main"
          :current="getCurrent()"
          caption="Contas"
          singular="Conta"
          :creatable="false"
          :deletable="false"
          :searchable="false"
          :titlable="false"
          :remoteConfig="false"
          :attach="false"
        />
      </v-tab-item>

      <v-tab-item :key="props.main.tabKey(1)" :transition="false" eager>
        <my-crud
          ref="aplicativo"
          name="aplicativo"
          :main="props.main"
          :current="getCurrent()"
          caption="Aplicativos"
          singular="Aplicativo"
          :creatable="false"
          :editable="false"
          :deletable="false"
          :searchable="false"
          :titlable="false"
          :attach="false"
        />
      </v-tab-item>

      <v-tab-item :key="props.main.tabKey(2)" :transition="false" eager>
        <my-crud
          ref="instancia"
          name="instancia"
          :main="props.main"
          :current="getCurrent()"
          caption="Instâncias"
          singular="Instância"
          :creatable="false"
          :editable="false"
          :deletable="false"
          :searchable="false"
          :titlable="false"
          :attach="false"
        />
      </v-tab-item>

      <v-tab-item :key="props.main.tabKey(3)" :transition="false" eager>
        <my-crud
          ref="convidado"
          name="convidado"
          :main="props.main"
          :current="getCurrent()"
          caption="Convidado"
          singular="Convidado"
          :creatable="false"
          :editable="false"
          :deletable="false"
          :searchable="false"
          :titlable="false"
          :attach="false"
        />
      </v-tab-item>
    </template>
  </my-crud>
</template>

<script>
  export default {
    data: () => ({
      expanded: 0,

      main: {
        fields: {
          foto: {
            type: 'img',
            editable: false,
            sortable: false,
            html: function (value) {
              return '<img width="40" src="' + value + '">'
            }
          },
          id: { readonly: true },
          nome: { readonly: false },
          senha: { type: 'password', gridable: false },
          email: { text: 'E-mail', readonly: true },
          telefone: { readonly: true },
          criadoEm: { text: 'Criado em', readonly: true },
          ultimoLogin: { text: 'Último login', readonly: true },
          grupos: {
            text: 'Permissão de acesso',
            items: [
              { value: 'adm', text: 'Admin' },
              { value: 'spt', text: 'Suporte' },
              { value: 'usr', text: 'Usuário' }
            ],
            multiple: true,
            class: 'xs12 lg6'
          },
          recusaAval: { text: 'Recusa avaliação' },
          recusaEm: { text: 'Recusou em', gridable: false },
          avaliouEm: { text: 'Avaliou no Google Play' },
          bloqueado: { }
        },
        sorted: true
      },

      conta: {
        fields: {
          foto: {
            editable: false,
            sortable: false,
            html: function (value) {
              return '<img width="40" src="' + value + '">'
            }
          },
          tipo: {
            text: 'Provedor',
            readonly: true,
            html: function (value, item, field) {
              for (let f of field.items) {
                if (f.value === value) {
                  value = f.text
                  break
                }
              }
              return value
            },
            items: [
              { value: 'G', text: 'Google' },
              { value: 'F', text: 'Facebook' },
              { value: 'E', text: 'Email' }
            ],
            multiple: false
          },
          id: { readonly: true },
          nome: { readonly: true },
          uid: { readonly: true },
          email: { editable: false },
          telefone: { editable: false },
          criadoEm: { editable: false },
          ultimoLogin: { editable: false },
          bloqueado: { }
        },
        sorted: true
      },

      aplicativo: {
        fields: {
          id: {}, 
          nome: { text: 'Nome curto' },
          descricao: { text: 'Descrição', class: 'xs12 md6' },
          bloqueado: {
            text: 'Status',
            default: '0',
            items: [
              { value: '0', text: 'Ativo' },
              { value: '1', text: 'Bloq.nova inst.' },
              { value: '2', text: 'Blooqueado uso' }
            ],
            rules: [(v) => !!v || 'Requerido']
          },
          serverhost: { text: 'Endereço servidor', class: 'xs12 md6' },
          usuario_id: { text: 'Proprietário', link: '/usuario', class: 'xs12 md4' },
          criadoEm: { text: 'Criado em', readonly: true },
          observacao: { text: 'Observação', class: 'xs12 md6' },
          foto: {
            type: 'img',
            sortable: false,
            html: function (value) {
              return '<img width="40" src="' + value + '">'
            },
            class: 'xs12 md4'
          }
        },
        sorted: true,
        columns: ['foto', 'id', 'nome', 'descricao', 'serverhost', 'observacao', 'criadoEm', 'bloqueado']
      },

      instancia: {
        fields: {
          id: { text: 'Instância', readonly: true },
          app_id: { gridable: false, editable: false, readonly: true },
          status: {
            items: [
              { value: '0', text: 'Instalar' },
              { value: '1', text: 'Instalado' },
              { value: '2', text: 'Upgrade' }
            ],
            readonly: true
          },
          plano: {
            items: [
              { value: '0', text: 'FREE' },
              { value: '1', text: 'Bronze' },
              { value: '2', text: 'Prata' },
              { value: '3', text: 'Ouro' },
              { value: '4', text: 'Diamante' }
            ],
            readonly: true
          },
          nome: { text: 'Empresa', readonly: true },
          cnpj: { text: 'CNPJ', readonly: true },
          mensagem: { text: 'Mensagem', class: 'xs12 md6' },
          serverhost: { text: 'Endereço servidor', class: 'xs12 md6' },
          usuario_id: { text: 'Proprietário', link: '/usuario', class: 'xs12 md4', readonly: true },
          qdeUsuario: { text: 'Qde usuários' },
          qdeSuporte: { text: 'Qde suporte' },
          criadoEm: { text: 'Criado em', readonly: true },
          observacao: { text: 'Observação', class: 'xs12 md6' },
          bloqueado: { },
          excluir: { text: 'Excluído em', readonly: true },
          foto: {
            type: 'img',
            sortable: false,
            editable: false,
            readonly: true,
            html: function (value) {
              return '<img width="40" src="' + value + '">'
            }
          }
        },
        sorted: true,
        columns: ['foto', 'id', 'app_id', 'nome', 'cnpj', 'mensagem', 'serverhost', 'criadoEm', 'bloqueado']
      },

      convidado: {
        fields: {
          id: { gridable: false, editable: false, readonly: true },
          instancia_id: { text: 'Instância', editable: false, readonly: true },
          usuario_id: { text: 'Usuário', link: '/usuario', class: 'xs12 md4', readonly: true },
          email: { text: 'Email do convite', class: 'xs12 md4', readonly: true },
          status: {
            text: 'Status',
            items: [
              { value: '0', text: 'Convidado' },
              { value: '1', text: 'Rejeitou' },
              { value: '2', text: 'Bloqueou' },
              { value: '8', text: 'Aceitou' },
              { value: '9', text: 'Bloqueado' }
            ],
            rules: [(v) => !!v || 'Requerido']
          },
          suporte: { text: 'Suporte', readonly: false },
          texto: { text: 'Texto do convite', class: 'xs12 md6', readonly: true },
          data: { text: 'Data', readonly: true },
          convidadoPor: { text: 'Convidado por', join: true, class: 'xs12 md4', readonly: true },
        },
        sorted: true,
        columns: ['instancia_id', 'email', 'data', 'status', 'suporte']
      }
    }),

    methods: {
      getCurrent () {
        return this
      }
    }
  }
</script>
