export default {
  standalone: false,
  router: null,
  callingAjax: 0,
  portalURI: '', // alterar em main.js
  serverURI: '', // alterar em main.js
  signInSuccessUrl: '/apps',
  instance: -1,  // portal
  instances: [],
  user: null,
  token: null,
  loginUsername: false,
  groups: [],
  routes: [],
  joinLink: '',
  forceRefresh: false,
  alerts: [],
  captionError: 'Mensagem de erro',
  errors: [],
  favorites: [],
  recent: [],
  currentTab: null
}
