<template>
  <div>
    <my-crud
      ref="instancia"
      caption="Instâncias"
      singular="Instância"
      :deletable="false"
      :showUnique="false"
    >
      <template v-slot:btnEdit="props">
        <v-btn
          v-if="props.main.editedItem.status != 1"
          class="elevation-3 mr-4 white--text"
          color="indigo"
          small
          :loading="instalando"
          @click="instMysql=true"
        >
          Instalar Mysql
        </v-btn>
      </template>

      <template v-slot="props">
        <my-crud
          name="usuario"
          :main="props.main"
          :current="getCurrent()"
          caption="Usuários"
          singular="Usuário"
          :creatable="false"
          :editable="true"
          :deletable="false"
          :searchable="false"
          :attach="false"
          :showUnique="false"
          :iconFavorite="false"
        />
      </template>
    </my-crud>

    <!--
      Instalar MySql
    -->
    <v-dialog v-model="instMysql" width="70%" :persistent="instalando">
      <v-card>
        <v-card-title class="indigo white--text" style="font-size: 1rem;">
          Instalar MySql DB
        </v-card-title>
        <v-container class="px-10 py-5">
          <div :disabled="instalando">
            <h3 class="mb-4">Banco de dados INSTÂNCIA</h3>
            <v-text-field label="URL" v-model="db.host" />
            <v-text-field label="Nome" v-model="db.name" />
            <v-text-field label="Usuário" v-model="db.user" />
            <v-text-field label="Senha" v-model="db.pwd"
              :append-icon="e1 ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="() => (e1 = !e1)"
              :type="e1 ? 'password' : 'text'"
            />
            <br/>
            <h3 class="mb-4">Banco de dados HISTÓRICO</h3>
            <v-text-field label="URL" v-model="archive.host" />
            <v-text-field label="Nome" v-model="archive.name" />
            <v-text-field label="Usuário" v-model="archive.user" />
            <v-text-field label="Senha" v-model="archive.pwd"
              :append-icon="e1 ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="() => (e1 = !e1)"
              :type="e1 ? 'password' : 'text'"
            />
            <v-text-field label="Tabela" v-model="archive.table" />
            <v-card-actions>
              <v-layout align-center justify-end row>
                <v-btn class="mr-3 white--text" color="indigo" small @click="migrar" :loading="instalando">OK</v-btn>
                <v-btn small @click="instMysql=false" :loading="instalando">Cancelar</v-btn>
              </v-layout>
            </v-card-actions>
          </div>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import ajax from '@/js/base/ajax'

  export default {
    data: () => ({
      instMysql: false,
      instalando: false,
      e1: false,

      db: {
        host: 'mariadb-inumero',
        name: 'inst',
        user: 'root',
        pwd: '',
      },
      archive: {
        host: 'mariadb-inumero',
        name: 'erp_archive',
        user: 'root',
        pwd: '',
        table: 'inst'
      },
      
      // db: {
      //   host: 'localhost',
      //   name: 'inst1',
      //   user: 'root',
      //   pwd: 'monalisa',
      // },
      // archive: {
      //   host: 'localhost',
      //   name: 'sobc_archive',
      //   user: 'root',
      //   pwd: 'monalisa',
      //   table: 'inst1'
      // },

      main: {
        fields: {
          id: {},
          app_id: { text: 'App', link: '/aplicativo', readonly: true },
          nome: { text: 'Empresa', readonly: true },
          status: {
            default: '0',
            items: [
              { value: '0', text: 'Instalar' },
              { value: '1', text: 'Pronto' },
              { value: '2', text: 'Upgrade' }
            ],
            rules: [(v) => !!v || 'Requerido']
          },
          plano: {
            default: '0',
            items: [
              { value: '0', text: 'FREE' },
              { value: '1', text: 'Bronze' },
              { value: '2', text: 'Prata' },
              { value: '3', text: 'Ouro' },
              { value: '4', text: 'Diamante' }
            ],
            rules: [(v) => !!v || 'Requerido']
          },
          criadoEm: { text: 'Criado em', readonly: true },
          cnpj: { text: 'CNPJ', readonly: true },
          celular: { text: 'Celular', readonly: true },
          mensagem: { text: 'Mensagem', class: 'xs12 md4' },
          serverhost: { text: 'Endereço servidor', class: 'xs12 md4' },
          usuario_id: { text: 'Proprietário', link: '/usuario', class: 'xs12 md4', readonly: true },
          qdeUsuario: { text: 'Qde usuários' },
          qdeSuporte: { text: 'Qde suporte' },
          observacao: { text: 'Observação', class: 'xs12 md4' },
          msgUsuario: { text: 'Mensagem pro usuário', hint: 'Aceita formato HTML', class: 'xs12 md4' },
          msgGrupos: { text: 'Grupos de usuário que receberão a mensagem', items: [], combobox: true, multiple: true, class: 'xs12 md4', hint: 'Não preenchido: Envia para todos usuários' },
          bloqueado: { },
          excluir: { text: 'Excluído em' },
          foto: {
            type: 'img',
            sortable: false,
            editable: false,
            readonly: true,
            html: function (value) {
              return '<img width="40" src="' + value + '">'
            }
          }
        },
        sorted: true,
        columns: ['foto', 'id', 'app_id', 'plano', 'status', 'usuario_id', 'nome', 'cnpj', 'celular', 'mensagem', 'serverhost', 'criadoEm', 'bloqueado']
      },

      usuario: {
        fields: {
          id: { gridable: false, editable: false, readonly: true },
          instancia_id: { gridable: false, editable: false, readonly: true },
          usuario_id: { text: 'Usuário', link: '/usuario', class: 'xs12 md4', readonly: true },
          email: { text: 'Email do convite', class: 'xs12 md4', readonly: true },
          status: {
            text: 'Status',
            items: [
              { value: '0', text: 'Convidado' },
              { value: '1', text: 'Rejeitou' },
              { value: '2', text: 'Bloqueou' },
              { value: '8', text: 'Aceitou' },
              { value: '9', text: 'Bloqueado' }
            ],
            rules: [(v) => !!v || 'Requerido']
          },
          suporte: { text: 'Suporte', readonly: false },
          texto: { text: 'Texto do convite', class: 'xs12 md6', readonly: true },
          data: { text: 'Data', readonly: true },
          convidadoPor: { text: 'Convidado por', join: true, class: 'xs12 md4', readonly: true }
        },
        sorted: true,
        columns: ['usuario_id', 'email', 'data', 'status', 'suporte']
      }
    }),

    methods: {
      getCurrent () {
        return this
      },

      migrar () {
        var vm = this.$refs.instancia 
        if (vm) {
          var item = vm.editedItem
          var that = this
          that.instalando = true
          let urlERP = 'https://inumero.com.br/SERVER/erp/' //?  item.serverhost + '/SERVER/erp/'
          //? let urlERP = 'http://localhost/SERVER/erp/'

          ajax.request(
            'migrate.php', 
            {
              instToken: item.token,
              signature: item.chave,
              db: that.db,
              archive: that.archive
            },
            response => {
              if (response.data.confirmado) {
                item.status = '1'
                item.mensagem = ''
                vm.saveItem(item)
                that.instMysql = false
                that.db.name = 'inst' 
                that.db.user = 'inst' 
                that.archive.table = 'inst'
              }
            },
            null,
            () => {
              that.instalando = false
            },
            urlERP
          )
        }
      }
    }
  }
</script>
