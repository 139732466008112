<template>

<div>
  <v-card v-if="titlable" class="mt-4 mx-4">
    <v-card-title class="indigo white--text" style="font-size: 1rem;">
      <v-icon color="white" class="mr-2">mdi-file-document</v-icon>
      <span>POLÍTICA DE PRIVACIDADE</span>
      <v-spacer></v-spacer>
      <v-btn dark class="toolbtn mr-2" icon @click="print()">
        <v-icon>mdi-printer</v-icon>
      </v-btn>
    </v-card-title>
  </v-card>

  <div id="politica" :class="titlable ? 'ma-4' : 'ma-0'">

  <p><span class="my-body">O INÚMERO ("nós") leva a privacidade a sério. Por
  isso, neste termo de Política de Privacidade ("Política") explicamos
  as formas como nós utilizamos, armazenamos, compartilhamos e protegemos os
  dados pessoais coletados a partir do uso dos nossos serviços e soluções
  (coletivamente "Serviços") e da visita aos nossos websites. Também
  como as pessoas titulares dos dados podem exercer os direitos previstos na
  legislação aplicável.</span></p>

  <p><span class="my-title">1. Quem somos nós</span></p>

  <p><span class="my-body">O INÚMERO SISTEMAS oferece um portfólio de serviços de
  internet, que incluem, hospedagem de sites, soluções de computação em nuvem,
  sistemas de gestão diversos, loja virtual, intermediador de pagamento, entre
  outros.</span></p>

  <p><span class="my-body">&nbsp;</span></p>

  <p><span class="my-title">2. Definições importantes</span></p>

  <p><span class="my-body">Lei Geral de Proteção de Dados (LGPD). Lei Federal nº
  13.709 publicada no dia 14 de agosto de 2018 que regula as atividades de
  Tratamento de Dados Pessoais, inclusive nos meios digitais, por pessoa natural
  ou por pessoa jurídica de direito público ou privado, com o objetivo de
  proteger os direitos fundamentais de liberdade e de privacidade e o livre
  desenvolvimento da personalidade da pessoa natural.</span></p>

  <p><span class="my-body">&nbsp;</span></p>

  <p><span class="my-title">3. Clientes</span></p>

  <p><span class="my-body">3.1 Dados que coletamos</span></p>

  <p><span class="my-body">De forma geral, quando um Cliente (ou para os fins desta
  seção, "você") ou a sua empresa contrata, utiliza ou interage com os
  Serviços, nós coletamos os seus dados pessoais, que podem ser divididos em três
  grandes categorias:</span></p>

  <p><span class="my-body">(i) Dados que você nos fornece: quando você contrata,
  utiliza ou interage com os Serviços ou se comunica conosco de alguma forma,
  você (ou a sua empresa) pode nos <span class=SpellE>fornecer</span> alguns
  dados. Esses dados incluem:</span></p>

  <p><span class="my-body">Dados de
  contato profissional, como o seu nome, cargo, empresa, localização, número de
  telefone e endereço de e-mail;</span></p>

  <p><span class="my-body">Preferências de marketing;</span></p>

  <p><span class="my-body">Credenciais
  de conta, como o seu endereço de e-mail, usuário e senha;</span></p>

  <p><span class="my-body">Informações
  de pagamento, como dados de cartão de crédito, dados bancários e endereço para
  fatura;</span></p>

  <p><span class="my-body">Dados
  relacionados a suporte técnico e solução de problemas. Esses dados incluem
  dados de abertura de chamado, como o tipo de produto ou serviço relacionado ao
  seu pedido de ajuda, dados de contato ou autenticação e o conteúdo das suas
  comunicações conosco, incluindo gravações telefônicas.</span></p>

  <p><span class="my-body">(<span class=SpellE>ii</span>) Dados que coletamos
  automaticamente: quando você utiliza os nossos serviços, nós podemos coletar e
  armazenar dados de forma automática. Esses dados incluem:</span></p>

  <p><span class="my-body">Dados de
  dispositivos que você usa para acessar os Serviços, como o seu endereço de IP,
  sistema operacional, informação de geolocalização e identificador do
  dispositivo.</span></p>

  <p><span class="my-body">Dados de
  registros, como o endereço de IP e suas atividades nos Serviços (como por
  exemplo a data e hora associadas com o seu uso, as páginas e arquivos visualizados,
  pesquisas e outras ações que você realiza (como, por exemplo, quais
  funcionalidades você utilizou).</span></p>

  <p><span class="my-body">Dados de
  navegação, ou seja, dados sobre como você utiliza e interage com os nossos
  Serviços, o que pode incluir das datas em que você utilizou os Serviços e as
  suas atividades de navegação. Nós também coletamos informações sobre a
  performance dos Serviços, incluindo métricas relacionadas ao envio de e-mails e
  outro tipo de comunicação que você envia por meio dos Serviços.</span></p>

  <p><span class="my-body">(<span class=SpellE>iii</span>) Dados que coletamos de
  outras fontes: nós podemos coletar dados de outras fontes, como por exemplo
  bases de dados públicas, dados de perfis públicos em redes sociais e entidades
  de proteção ao crédito.</span></p>

  <p><span class="my-body">3.2 Como utilizamos esses dados</span></p>

  <p><span class="my-body">Nós utilizamos os dados coletados ou recebidos a partir da
  utilização dos Serviços para as seguintes finalidades:</span></p>

  <p><span class="my-body">Cumprir com
  nossas obrigações contratuais com você ou com a sua empresa. Isso inclui criar
  e gerenciar as contas que você possa ter conosco, verificar sua identidade,
  cobrar os valores devidos por você, fornecer, personalizar e aprimorar nossos
  serviços;</span></p>

  <p><span class="my-body">Notificá-lo
  sobre eventuais alterações em nossos produtos e serviços;</span></p>

  <p><span class="my-body">Realizar
  operações internas, incluindo suporte, solução de problemas, análise de dados,
  testes, pesquisas e estatística;</span></p>

  <p><span class="my-body">Reforçar
  nossos procedimentos de segurança e proteção, visando a prestação de um serviço
  mais seguro e eficaz;</span></p>

  <p><span class="my-body">Fornecer informações sobre outros serviços e/ou produtos
  que oferecemos, similares aos já contratados por você;</span></p>

  <p><span class="my-body">Avaliar ou
  entender a eficácia da publicidade que veiculamos, visando fornecer
  publicidades relevantes para você;</span></p>

  <p><span class="my-body">Gerenciar
  riscos e detectar, prevenir e/ou remediar fraudes ou outras atividades
  potencialmente ilegais ou proibidas, além de violações de políticas, contratos
  ou termos de uso aplicáveis;</span></p>

  <p><span class="my-body">Conforme
  necessário, para estabelecer, exercer e defender direitos em processos
  judiciais, administrativos ou arbitrais;</span></p>

  <p><span class="my-body">Cumprir com
  obrigações legais ou regulatórias, ou conforme exigido em um processo judicial,
  por qualquer órgão de aplicação da lei ou do governo com competência, ou que
  alegue ter competência, sobre o INÚMERO.</span></p>

  <p><span class="my-body">Para outras
  finalidades as quais fornecemos um aviso específico no momento da coleta, ou,
  de outro modo, conforme autorizado ou exigido por lei.</span></p>

  <p><span class="my-body">&nbsp;</span></p>

  <p><span class="my-title">4. Consumidores</span></p>

  <p><span class="my-body">Essa seção se aplica aos dados pessoais de Consumidores de
  nossos Clientes (ou para os fins desta seção, "você"), quando
  atuarmos como Controladores. Note que em grande parte dos produtos e serviços
  que oferecemos, a empresa INÚMERO atua como Operadoras dos dados
  pessoais, segundo as instruções de nossos Clientes. O INÚMERO não é responsável
  pelas práticas de privacidade ou segurança dos nossos Clientes. Para entender
  como seus dados são tratados por nossos Clientes, é importante consultar as
  respectivas políticas de privacidade.</span></p>

  <p><span class="my-body">4.1 Dados que coletamos</span></p>

  <p><span class="my-body">(i) Dados que recebemos dos nossos Clientes: Os Cliente do
  INÚMERO podem nos <span class=SpellE>fornecer</span> os seus dados pessoais por
  meio dos Serviços. Quando, por exemplo, um Cliente faz o upload de sua base de
  contatos para utilizar os nossos serviços de e-mail marketing, quando você se
  inscreve para uma newsletter do Cliente, ou quando você realiza uma compra na
  loja virtual do Cliente, o Cliente pode nos <span class=SpellE>fornecer</span>
  determinadas informações como o seu nome, o seu endereço de e-mail, número de
  telefone ou dados de pagamento.</span></p>

  <p><span class="my-body">(<span class=SpellE>ii</span>) Dados que coletamos
  automaticamente: quando você interage com os Serviços adquiridos pelo Cliente,
  como por exemplo, quando você interage com uma comunicação de marketing, navega
  pelo website do Cliente ou realiza uma compra na loja virtual do Cliente, nós
  podemos coletar informações sobre o seu dispositivo e as suas atividades. Essas
  informações incluem:</span></p>

  <p><span class="my-body">Dados de
  dispositivos que você usa para acessar os Serviços, como o seu endereço de IP,
  sistema operacional, informação de geolocalização e identificadores únicos do
  dispositivo.</span></p>

  <p><span class="my-body">Dados de
  navegação, ou seja, dados sobre como você utiliza e interage com os nossos Serviços,
  o que pode incluir das datas em que você utilizou os Serviços e as suas
  atividades de navegação, como por exemplo as páginas que você visualizou, se
  você abriu um e-mail ou adicionou um produto no carrinho. Nós também coletamos
  informações sobre a performance dos Serviços, incluindo métricas relacionadas
  ao envio de e-mails ou outras formas de comunicação enviados pelos nossos
  Clientes por meio dos Serviços.</span></p>

  <p><span class="my-body">(<span class=SpellE>iii</span>) Dados que coletamos de
  outras fontes: nós podemos coletar dados de outras fontes, como por exemplo
  bases de dados públicas, dados de perfis públicos em redes sociais, entidades
  de proteção ao crédito, sobretudo quando você realiza uma compra em um website
  que utiliza os nossos serviços de intermediação de pagamento.</span></p>

  <p><span class="my-body">4.2 Como utilizamos esses dados</span></p>

  <p><span class="my-body">Nós podemos utilizar os dados coletados ou recebidos a
  partir da utilização dos Serviços pelos nossos Clientes para as seguintes
  finalidades:</span></p>

  <p><span class="my-body">Fornecer,
  personalizar e aprimorar nossos Serviços;</span></p>

  <p><span class="my-body">Realizar
  operações internas, incluindo suporte aos nossos clientes, solução de
  problemas, análise de dados, testes, pesquisas e estatística;</span></p>

  <p><span class="my-body">Desenvolver
  estratégias de marketing para nossos Clientes, para que estes possam oferecer
  publicidades relevantes para você.</span></p>

  <p><span class="my-body">Prosseguir
  com pedidos de pagamento efetuados por você, nas operações de intermediação de
  pagamento.</span></p>

  <p><span class="my-body">Gerenciar
  riscos e detectar, prevenir e/ou remediar fraudes ou outras atividades
  potencialmente ilegais ou proibidas, além de violações de políticas ou termos
  de uso aplicáveis;</span></p>

  <p><span class="my-body">Estabelecer, exercer e defender direitos em processos judiciais,
  administrativos ou arbitrais;</span></p>

  <p><span class="my-body">Cumprir com
  obrigações legais ou regulatórias, ou conforme exigido em um processo judicial,
  por qualquer órgão de aplicação da lei ou do governo com competência, ou que
  alegue ter competência, sobre o INÚMERO.</span></p>

  <p><span class="my-body">Para outras
  finalidades as quais fornecemos um aviso específico no momento da coleta, ou,
  de outro modo, conforme autorizado ou exigido por lei.</span></p>

  <p><span class="my-body"></span></p>

  <p><span class="my-title">5. Usuários</span></p>

  <p><span class="my-body">Essa seção se aplica aos dados pessoais que coletamos
  quando os Usuários (ou para os fins dessa seção, "você") visitam os
  websites do INÚMERO.</span></p>

  <p><span class="my-body">5.1 Dados que coletamos</span></p>

  <p><span class="my-body">(i) Dados que você nos fornece: os nossos websites dispõem
  de diversas formas por meio das quase você pode entrar em contato conosco. Você
  pode nos fornecer dados pessoais, por exemplo, quando você manifesta interesse
  em obter mais informações sobre os nossos produtos e serviços, participa de
  pesquisas, se inscreve no nosso newsletter, faz o download de conteúdo
  informativo ou entra em contato conosco de alguma outra forma. Esses dados
  incluem:</span></p>

  <p><span class="my-body">Dados de
  contato profissional, como o seu nome, cargo, empresa, localização, número de
  telefone e endereço de e-mail;</span></p>

  <p><span class="my-body">Área de
  atuação e interesses;</span></p>

  <p><span class="my-body">Natureza da
  sua comunicação conosco;</span></p>

  <p><span class="my-body">Preferências de marketing;</span></p>

  <p><span class="my-body">Quaisquer
  outras informações que você escolha nos <span class=SpellE>fornecer</span>.</span></p>

  <p><span class="my-body">(<span class=SpellE>ii</span>) Dados que coletamos
  automaticamente: quando você utiliza os nossos websites, nós podemos coletar e
  armazenar dados de forma automática. Esses dados incluem:</span></p>

  <p><span class="my-body">Dados de
  dispositivos que você usa para acessar os Serviços, como o seu endereço de IP,
  sistema operacional, informações de navegador, informação de geolocalização e
  identificadores únicos do dispositivo.</span></p>

  <p><span class="my-body">Dados de
  navegação, ou seja, dados sobre como você utiliza e interage com os nossos
  Serviços, o que pode incluir das datas em que você utilizou os Serviços e as
  suas atividades de navegação, incluindo por exemplo a sua interação com os
  nossos materiais informativos. Nós também coletamos informações sobre a
  performance dos Serviços, incluindo métricas relacionadas ao envio de e-mails e
  outro tipo de comunicação que você envia por meio dos Serviços.</span></p>

  <p><span class="my-body">5.2 Como utilizamos esses dados</span></p>

  <p><span class="my-body">Nós podemos utilizar os dados coletados ou recebidos a
  partir da utilização dos websites para as seguintes finalidades:</span></p>

  <p><span class="my-body">Fornecer,
  personalizar e aprimorar nossos websites;</span></p>

  <p><span class="my-body">Reforçar
  nossos procedimentos de segurança e proteção, visando a prestação de um serviço
  mais seguro e eficaz;</span></p>

  <p><span class="my-body">Realizar
  operações internas, solução de problemas, análise de dados, testes, pesquisas e
  estatística;</span></p>

  <p><span class="my-body">Processar
  transações e gerenciar as suas contas online;</span></p>

  <p><span class="my-body">Enviar
  comunicações de marketing e recomendações de produtos ou serviços oferecidos
  pelo INÚMERO, de acordo com as suas preferências de marketing.</span></p>

  <p><span class="my-body">Avaliar ou
  entender a eficácia da publicidade que veiculamos, visando fornecer
  publicidades relevantes para você;</span></p>

  <p><span class="my-body">Gerenciar
  riscos e detectar, prevenir e/ou remediar fraudes ou outras atividades
  potencialmente ilegais ou proibidas, além de violações de políticas ou termos
  de uso aplicáveis;</span></p>

  <p><span class="my-body">Conforme
  necessário, para estabelecer, exercer e defender direitos em processos
  judiciais, administrativos ou arbitrais;</span></p>

  <p><span class="my-body">Cumprir com
  obrigações legais ou regulatórias, ou conforme exigido em um processo judicial,
  por qualquer órgão de aplicação da lei ou do governo com competência, ou que
  alegue ter competência, sobre o INÚMERO.</span></p>

  <p><span class="my-body">Para outras
  finalidades as quais fornecemos um aviso específico no momento da coleta, ou,
  de outro modo, conforme autorizado ou exigido por lei.</span></p>

  <p><span class="my-body"></span></p>

  <p><span class="my-title">6. Como compartilhamos dados pessoais</span></p>

  <p><span class="my-body">Nós poderemos compartilhar os dados pessoais coletados com
  outra empresa seu grupo econômico, com terceiros e parceiros de negócio,
  que sejam relevantes para fins de viabilizar a prestação dos Serviços. Referido
  compartilhamento ocorre com base nos seguintes critérios e para as finalidades
  descritas abaixo.</span></p>

  <p><span class="my-body">Empresa do
  Grupo: a empresa INÚMERO pode compartilhar entre si os dados pessoais
  coletados a partir da prestação dos Serviços a fim de operar, executar,
  aprimorar, entender, personalizar, dar suporte, anunciar nossos Serviços e
  prevenir fraudes.</span></p>

  <p><span class="my-body">Prestadores
  de serviço terceirizados: Trabalhamos com prestadores de serviço terceirizados
  para nos ajudar a operar, executar, aprimorar, entender, personalizar, dar
  suporte e anunciar nossos Serviços. Quando compartilhamos dados com prestadores
  de serviço terceirizados, exigimos que eles utilizem seus dados de acordo com
  nossas instruções e termos ou mediante seu consentimento expresso, quando
  aplicável.</span></p>

  <p><span class="my-body">Entidades
  de Proteção ao Crédito: Podemos compartilhar os seus dados cadastrais com
  entidades dedicadas a reduzir o risco de crédito e proteger empresas e
  indivíduos contra fraudes, a fim de validar as informações fornecidas por você.</span></p>

  <p><span class="my-body">Órgãos
  reguladores, autoridades judiciais ou administrativas: podemos compartilhar as
  suas informações pessoais para prestar às autoridades competentes todas as
  informações que forem solicitadas com relação ao Titular e operações por ele
  executadas nos Sites. Ademais, podemos compartilhar os seus dados pessoais com
  autoridades públicas ou entidades privadas para combate à fraude e abuso no uso
  dos Serviços, para investigação de suspeitas de violação da lei, ou para combater
  qualquer outra suspeita de descumprimento das nossas políticas e contratos.</span></p>

  <p><span class="my-body">Transferência de Ativos: Caso uma empresa ou unidade de negócio do
  INÚMERO seja reorganizada ou vendida e sejam transferidos todos ou
  substancialmente todos os ativos a um novo proprietário, sua informação pessoal
  pode ser transferida ao comprador independentemente de sua autorização para
  garantir a continuidade dos serviços;</span></p>

  <p><span class="my-body">Com a sua
  autorização: Em outros casos não previstos acima, havendo o objetivo de
  compartilhamento dos dados pessoais e informações, enviaremos você uma
  notificação com informações a respeito de tal compartilhamento para solicitar o
  seu consentimento, para finalidade determinada.</span></p>

  <p><span class="my-body"></span></p>

  <p><span class="my-title">7. Cookies e Tecnologias de Rastreamento</span></p>

  <p><span class="my-body">Nós e nossos parceiros podemos utilizar diversas
  tecnologias para coletar e armazenar, de forma automática, dados sobre o uso
  dos Serviços. Essas tecnologias incluem cookies, pixels, web beacons e <span
  class=SpellE>SDKs</span>. Nós usamos as informações coletadas por meio dessas
  tecnologias para otimizar e personalizar a sua experiência de navegação,
  direcionar campanhas publicitárias, desenvolver e aplicar funcionalidades de
  segurança e procedimentos de prevenção à fraude, dentre outras possíveis
  utilizações. Para mais informações, acesse a nossa Política de Cookies,
  disponível em nosso site no link: <a href="https://www.inumero.com.br/politicas">https://www.inumero.com.br/politicas</a>.</span></p>

  <p><span class="my-body">&nbsp;</span></p>

  <p><span class="my-title">8. Transferências de dados pessoais para fora do Brasil</span></p>

  <p><span class="my-body">Nós poderemos transferir alguns de seus dados pessoais a
  prestadores de serviços localizados no exterior, incluindo prestadores de
  serviços em nuvem.</span></p>

  <p><span class="my-body">Quando seus dados pessoais forem transferidos para fora do
  Brasil, O INÚMERO adotará medidas apropriadas para garantir a proteção adequada
  de seus dados pessoais em conformidade com os requisitos da legislação
  aplicável de proteção de dados, incluindo por meio da celebração de contratos
  apropriados de transferência de dados com terceiros de seus dados pessoais
  quando exigidos.</span></p>

  <p><span class="my-body">&nbsp;</span></p>

  <p><span class="my-title">9. Interações Com Sites De Terceiros</span></p>

  <p><span class="my-body">Podemos disponibilizar links para outros sites na Internet.
  O INÚMERO NÃO SE RESPONSABILIZA POR ESTES SITES E CONTEÚDOS E, AINDA, NÃO
  COMPARTILHA, SUBSCREVE, MONITORA, VALIDA OU ACEITA A FORMA COMO ESSES SITES OU
  FERRAMENTAS DE ARMAZENAMENTO DE CONTEÚDO COLETAM, PROCESSAM E TRANSFEREM SUAS
  INFORMAÇÕES PESSOAIS E PRIVADAS. Recomendamos que você consulte as respectivas
  políticas de privacidade de tais sites para se informar adequadamente a
  respeito do uso de suas informações pessoais por outros sites ou outras
  ferramentas.</span></p>

  <p><span class="my-body">Podemos registrar o seu acesso aos sites de terceiros, com
  o intuito de medir a relevância desses sites aos nossos usuários.</span></p>

  <p><span class="my-body">&nbsp;</span></p>

  <p><span class="my-title">10. Segurança</span></p>

  <p><span class="my-body">Os Dados Pessoais tratados pelo INÚMERO são protegidos por
  medidas físicas, técnicas e organizacionais de segurança para reduzir riscos de
  perda, mau uso e acesso não autorizado, divulgação e alteração, tais como
  firewalls e criptografia de dados, controles de acesso físico a data centers,
  além de controles de autorização de acesso à informação.</span></p>

  <p><span class="my-body">&nbsp;</span></p>

  <p><span class="my-title">11. Direitos do Titular</span></p>

  <p><span class="my-body">Os Titulares de dados pessoais têm alguns direitos no que
  se refere aos seus dados pessoais e podem exercê-los entrando em contato
  conosco por meio do e-mail: privacidade@inumero.com.br.</span></p>

  <p><span class="my-body">Confirmação
  da existência de tratamento de dados pessoais;</span></p>

  <p><span class="my-body">Acesso aos
  dados pessoais, nos termos da legislação aplicável;</span></p>

  <p><span class="my-body">Correção de
  dados incompletos, inexatos ou desatualizados;</span></p>

  <p><span class="my-body"><span>
  </span>Portabilidade dos dados;</span></p>

  <p><span class="my-body">Exclusão de
  dados, quando este forem tratados com base no consentimento do Titular ou
  quando os dados forem desnecessários, excessivos ou tratados em desconformidade
  com a legislação aplicável;</span></p>

  <p><span class="my-body">Solicitação
  de informações sobre uso compartilhado de dados. Note que essa Política de
  Privacidade descreve as atividades de compartilhamento de dados pessoais pelo
  INÚMERO. Para receber mais informações sobre como compartilhamos os seus dados
  entre em contato conosco;</span></p>

  <p><span class="my-body">Revogação
  do consentimento, quando aplicável.</span></p>

  <p><span class="my-body">Por motivos de segurança, somente poderemos atender à sua
  solicitação se tivermos certeza da sua identidade. Sendo assim, poderemos
  solicitar dados ou informações adicionais para a confirmação da identidade e da
  autenticidade do Titular. Note que, como mencionado acima, em grande parte dos
  produtos e serviços que oferecemos, o INÚMERO atua como operador dos dados
  pessoais, segundo as instruções de nossos Clientes. Caso você seja um
  Consumidor e deseje exercer os seus direitos com relação aos dados pessoais que
  o INÚMERO trata enquanto operador, você deve contatar o Cliente que está
  utilizando os Serviços.</span></p>

  <p><span class="my-body">&nbsp;</span></p>

  <p><span class="my-title">12. Término do Tratamento</span></p>

  <p><span class="my-body">Esta Política de Privacidade se aplica às circunstâncias
  acima mencionadas durante todo o período em que o INÚMERO armazenar os dados
  pessoais. Nós armazenamos e mantemos suas informações: (i) pelo tempo exigido
  por lei; (<span class=SpellE>ii</span>) até o término do tratamento de dados
  pessoais, conforme mencionado abaixo; ou (<span class=SpellE>iii</span>) pelo
  tempo necessário a preservar o legítimo interesse do INÚMERO. Assim, trataremos
  seus dados, por exemplo, durante os prazos prescricionais aplicáveis ou
  enquanto necessário para cumprimento de obrigação legal ou regulatória.</span></p>

  <p><span class="my-body">O término do tratamento de dados pessoais ocorrerá nos
  seguintes casos: (i) quando a finalidade para qual o dado pessoal foi coletado
  for alcançada, e/ou os dados pessoais coletados deixarem de ser necessários ou
  pertinentes ao alcance de tal finalidade; (<span class=SpellE>ii</span>) quando
  o Titular solicitar a exclusão de seus dados; e (<span class=SpellE>iii</span>)
  quando houver uma determinação legal neste sentido.</span></p>

  <p><span class="my-body">Nos casos de término de tratamento de dados pessoais,
  ressalvadas as hipóteses estabelecidas pela legislação aplicável ou pela
  presente Política de Privacidade, os dados pessoais serão eliminados e esta
  Política não será mais aplicável ao seu relacionamento com o INÚMERO.</span></p>

  <p><span class="my-body">&nbsp;</span></p>

  <p><span class="my-title">13. Alteração na Política de Privacidade</span></p>

  <p><span class="my-body">Com a constante evolução do modelo de negócio, o INÚMERO
  reserva-se o direito de alterar esta política de privacidade a qualquer
  momento, mediante publicação da versão atualizada em seu website. Caso exista
  uma alteração material no tratamento dos dados pessoais, você será informado e
  terá a oportunidade de analisar a política revisada antes de decidir continuar
  usando nossos Serviços.</span></p>

  <p><small>Versão 1.0 de 03/11/2020</small></p>

  </div>
</div>
</template>

<script>
  export default {
    props: {
      titlable: { type: Boolean, default: true }
    },

    methods: {
      print () {
        let w = window.open()
        let html = document.getElementById('politica').innerHTML
        let title = '<head><title>INÚMERO</title></head><h3>POLÍTICA DE PRIVACIDADE - INÚMERO SISTEMAS</h3>'
        w.document.write(title + html)
        w.print()
        w.close()
      }
    }
  }
</script>

<style scoped>
  .my-title {
    font-size: 12pt;	
    font-weight: bold;
    color: black;
  }
  .my-body {
    font-size: 10.5pt;
    color: black;
    line-height: 107%;
  }
  .SpellE {
    font-style: italic;
  }
</style>