import axios from 'axios'
import qs from 'qs'
import store from '@/store'
import firebase from 'firebase/app'
import { toLink } from '@/js/base/misc'
// import auth from '@/js/base/auth'

const TENTATIVAS = 1


function ajaxError (error) {
  // console.log(error)
  // console.trace()
  let msg = typeof (error) === 'string' ? error : error.message
  store.commit('ADD_ERROR', msg)
}


function send (count, values, callback2, callerr2, callfin2, router, silent=false, ajax_tentativas=TENTATIVAS) {
  axios(values)
  .then(function (response) {
    // var data = atob(response.data)
    // data = JSON.parse(data)
    // response.data = data
    var data = response.data
    var url
    if (data === 'token-expired') {
      url = window.location.pathname + '?' + window.location.search
      // auth.logout()
      firebase.auth().signOut()
      store.commit('RESET')
      router.push(url)
      ajaxError({message: 'Sua sessão expirou! Será necessário ENTRAR no sistema novamente.'})
    } else {
      if (data.captionError) {
        store.commit('CAPTION_ERROR', data.captionError)
      }
      if (data.error) {
        ajaxError({message: data.error})
      }
      if (data.message) {
        store.commit('ADD_ALERT', data.message)
      }
      if (typeof (data) === 'string') {
        throw data
      }
      callback2(response)
      if (data.redirect) {
        document.body.setAttribute('style', 'cursor: wait;')
        setTimeout(function () { toLink (null, router, data.redirect, '') }, 500)
      }
    }
    count = ajax_tentativas
  })
  .catch(function (error) {
    if (error.message === 'Network Error') {
      store.commit('ADD_ALERT', 'Sem sinal de internet ou servidor inoperante')
      error = ''
    }
    if (count < ajax_tentativas) {
      error = ''
      send(count+1, values, callback2, callerr2, callfin2, router, silent, ajax_tentativas)
    }
    if (error && callerr2(error)) {
      count = ajax_tentativas
      ajaxError(error)
    }
  })
  .finally(function () {
    if (count >= ajax_tentativas) {
      if (silent !== true) {
        store.commit('DEC_AJAX')
      }
      callfin2()
    }
  })
}


export default {
  request (uri, data = {}, callback = null, callerr = null, callfin = null, host = null, requiredInst = true) {
    const method = 'POST'
    let token = store.state.token
    if (!token) {
      // ajaxError({message: 'Requisição POST requer autenticação'})
      return
    }

    if (!host) {
      host = store.state.serverURI
    }
    let url = host + uri
    let instance = store.state.instance

    if (requiredInst && !instance) {
      // ajaxError({message: 'Requisição POST requer INSTANCIA definida'})
      return
    }

    // axios.defaults.headers['Authorization'] = token
    var silent = data.silent || false
    data['Authorization'] = token
    data['Instance'] = instance
    data['clientVersion'] = store.state.clientVersion
    if (data) {
      data = qs.stringify(data)
    }

    var callback2 = callback || (() => {})
    var callerr2 = callerr || (() => { return true })
    var callfin2 = callfin || (() => {})
    var router = store.state.router
    if (silent !== true) {
      store.commit('INC_AJAX')
    }

    let ajax_tentativas = TENTATIVAS
    if (uri == 'grants.php') {
      ajax_tentativas = 5
    }
    send(1, { method, url, data }, callback2, callerr2, callfin2, router, silent, ajax_tentativas)
  },

  get (uri, callback = null, callerr = null, callfin = null) {
    var callback2 = callback || (() => {})
    var callerr2 = callerr || ajaxError
    var callfin2 = callfin || (() => {})
    store.commit('INC_AJAX')

    axios.get(uri)
      .then(function (response) {
        callback2(response)
      })
      .catch(function (error) {
        callerr2(error)
      })
      .finally(function () {
        store.commit('DEC_AJAX')
        callfin2()
      })
  },

  error (error) {
    ajaxError(error)
  }
}
