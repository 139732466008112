import Vue from 'vue'
import './registerServiceWorker'
import vuetify from './plugins/vuetify';
import 'vuetify/dist/vuetify.min.css'
import routes from '@/js/routes'
import store from '@/store'
import auth from '@/js/base/auth'
import { getCookie } from '@/js/base/misc'
import App from '@/components/App'
import { domain, count, pretty, join, pluralize, formatFloat, prettyBool, prettyDate } from '@/js/base/filters'
import '@mdi/font/css/materialdesignicons.css'

Vue.filter('count', count)
Vue.filter('domain', domain)
Vue.filter('pretty', pretty)
Vue.filter('prettyBool', prettyBool)
Vue.filter('prettyDate', prettyDate)
Vue.filter('join', join)
Vue.filter('pluralize', pluralize)
Vue.filter('formatFloat', formatFloat)

import VueRouter from 'vue-router'
import { sync } from 'vuex-router-sync'
Vue.use(VueRouter)

const CrudView = () => import(/* webpackChunkName: "base" */ '@/components/base/Crud.vue')
Vue.component('my-crud', CrudView)

// https://www.npmjs.com/package/vue-tree-halower
import VTree from 'vue-tree-halower'
import 'vue-tree-halower/dist/halower-tree.min.css'
Vue.use(VTree)

// https://www.npmjs.com/package/vue-clip
import VueClip from 'vue-clip'
Vue.use(VueClip)

// https://github.com/yariksav/vuetify-confirm
import VuetifyConfirm from 'vuetify-confirm'
Vue.use(VuetifyConfirm, {
  vuetify,
  buttonTrueText: 'SIM',
  buttonFalseText: 'NÃO',
  color: 'info',
  icon: 'mdi-comment-question',
  title: ' ',
  width: 350,
  persistent: true,
  property: '$confirm'
})

Vue.config.productionTip = false

var router = new VueRouter({
  routes: routes,
  mode: 'history',
  // base: process.env.BASE_URL,
  linkExactActiveClass: 'active',
  scrollBehavior: function (to, from, savedPosition) {
    return savedPosition || { x: 0, y: 0 }
  }
})

// Some middleware to help us ensure the user is authenticated.
router.beforeEach((to, from, next) => {
  let noUser = (!router.app.$store.state.user) || router.app.$store.state.user === undefined
  let requireAuth = to.matched.some(record => ((record.meta.requireAuth === undefined) || record.meta.requireAuth))
  if (to.path === '/login' && !noUser) {
    next({ path: '/home' })
  } else if (from.path === '/login' && noUser && !requireAuth) {
    store.commit('ADD_RECENT', to.path)
    next()
  } else if (requireAuth && noUser) {
    next({ path: '/login', query: { redirect: to.fullPath } })
  } else if (from.query.redirect && from.query.redirect !== to.fullPath) {
    next({ path: from.query.redirect })
  } else {
    // store.commit('RESTART_AJAX')
    store.commit('ADD_RECENT', to.path)
    // if (to.query.s)
    //   store.commit('JOIN_LINK', to.path) // necessario para executar search de Favorito
    next()
  }
})

router.afterEach(() => {
  store.commit('FORCE_REFRESH')
})

sync(store, router)
store.state.router = router

let hostname = window.location.hostname
store.state.portalURI = '//' + hostname + '/SERVER/portal/'
store.state.serverURI = '//' + hostname + '/SERVER/portal/'

store.state.standalone = navigator.standalone || window.matchMedia('(display-mode: standalone)').matches ||
    ((screen.height-document.documentElement.clientHeight) < 90)

var idToken = getCookie('token')
if (idToken) {
  store.commit('SET_TOKEN', idToken)
} else {
  store.commit('LOAD_USER')
}

// compatibilizar vuetify-image-input com vuetify 2.0
import VIcon from '../node_modules/vuetify/lib/components/VIcon'
import VBtn from '../node_modules/vuetify/lib/components/VBtn'
import VSpacer from '../node_modules/vuetify/lib/components/VGrid/VSpacer'
import VSlider from '../node_modules/vuetify/lib/components/VSlider'
Vue.component('VIcon', { extends: VIcon })
Vue.component('VBtn', { extends: VBtn })
Vue.component('VSpacer', { extends: VSpacer })
Vue.component('VSlider', { extends: VSlider })

// eslint-disable-next-line
new Vue({
  vuetify,
  router,
  store,
  beforeCreate () {
    auth.init(this)
    if (idToken)
      auth.request(null, null)
  },
  render: h => h(App)
}).$mount('#app')
