<template>
<div>
  <v-card v-if="titlable" class="mt-4 mx-4">
    <v-card-title class="indigo white--text" style="font-size: 1rem;">
      <v-icon color="white" class="mr-2">mdi-file-document</v-icon>
      <span>CONTRATO DE USO DO SISTEMA</span>
      <v-spacer></v-spacer>
      <v-btn dark class="toolbtn mr-2" icon @click="print()">
        <v-icon>mdi-printer</v-icon>
      </v-btn>
    </v-card-title>
  </v-card>

  <div id="contrato" :class="titlable ? 'ma-4' : 'ma-0'">

  <p class="my-title">1. Aceite dos Termos</p>

  <p class="my-body">Este Contrato de Licença de Usuário Final (“CLUF”) é um
  acordo legal entre o licenciado (pessoa física ou jurídica) (o “LICENCIADO”) e
  o INÚMERO SISTEMAS LTDA, pessoa jurídica de direito privado, inscrita no CNPJ
  sob n. 39.153.313/0001-15 com sede na Rua Januário Alves de Souza 798 CJ.3,
  Curitiba, Estado do Paraná, Brasil, CEP 81750-370 (a “LICENCIANTE”) para uso do
  programa de computador denominado INÚMERO, disponibilizado neste ato pela
  LICENCIANTE (o “APLICATIVO”) por meio do site “inumero.com.br” (“Site”). 
  Ao utilizar o APLICATIVO, mesmo que parcialmente ou a
  título de teste, o LICENCIADO estará vinculado aos termos deste CLUF,
  concordando com suas disposições. Em caso de discordância
  com os termos aqui apresentados, a utilização do APLICATIVO deve ser
  imediatamente interrompida pelo LICENCIADO.</p>

  <p class="my-body">O LICENCIADO não adquire, pelo presente instrumento ou pela
  utilização do APLICATIVO, nenhum direito de propriedade intelectual ou outros
  direitos exclusivos, incluindo patentes, desenhos, marcas, direitos autorais ou
  quaisquer direitos sobre informações confidenciais ou segredos de negócio, nem
  o conteúdo disponibilizado no Site. Quaisquer direitos não expressamente concedidos
  sob o presente instrumento são reservados.</p>

  <p class="my-body">&nbsp;</p>

  <p class="my-title">2. Declarações do LICENCIADO</p>

  <p class="my-body">O LICENCIADO declara ter pleno conhecimento dos direitos e
  obrigações decorrentes do presente CLUF, constituindo este instrumento o acordo
  completo entre as partes. Declara, ainda, ter lido, compreendido e aceito todos
  os seus termos e condições.</p>

  <p class="my-body">O LICENCIADO declara que leu, compreendeu e aceitou a
  política de privacidade, conforme publicada no site inumero.com.br/politica,
  consentindo livre e expressamente às ações de coleta,
  uso, armazenamento e tratamento das referidas informações e dados.</p>

  <p class="my-body">O LICENCIADO declara que está ciente de que para
  usufruir de algumas das funcionalidades do APLICATIVO, em especial, dos
  serviços de integração com a rede bancária, assinatura do XML para envio de
  notas fiscais e captura automática de XML no SEFAZ, seus dados e informações
  poderão ser enviados para Aplicativos de Terceiros. O LICENCIADO compreende e 
  concorda que o uso de suas Informações por um Aplicativo de Terceiros é regido 
  pelas políticas de privacidade do Aplicativo de Terceiros. A LICENCIANTE não 
  será responsável, sob qualquer hipótese, pelo tratamento dado pelas informações 
  por um Aplicativo Terceiro.</p>

  <p class="my-body">O LICENCIADO declara que está ciente que serviços de hospedagem
  de site, armazenamento na nuvem online e outros serviços de TI essenciais;
  podem ser realizados por terceiros alocados em território nacional ou 
  fora do país;</p>

  <p class="my-body">O LICENCIADO declara que está ciente que customizações somente 
  poderão ser realizados pelo LICENCIANTE, sendo vedado à terceiros a realização 
  que qualquer tipo de alteração no código fonte do APLICATIVO.</p>

  <p class="my-body">&nbsp;</p>

  <p class="my-title">3.<span></span>Licença de Uso do APLICATIVO</p>

  <p class="my-body">Sujeito aos termos e condições aqui estabelecidos, este CLUF
  concede ao LICENCIADO uma licença revogável, não exclusiva e intransferível
  para uso do APLICATIVO. O LICENCIADO não poderá utilizar e nem permitir que o
  APLICATIVO seja utilizado para outra finalidade que não seja o processamento de
  suas informações ou de pessoas jurídicas indicadas pelo LICENCIADO no ato do
  cadastramento, exceto caso o LICENCIADO seja empresa de contabilidade que
  utiliza o APLICATIVO para processar informações de seus clientes, observados os
  limites estabelecidos neste CLUF. Esta licença não implica a capacidade de
  acessar outros APLICATIVOS além daqueles originalmente localizados no
  APLICATIVO. Em nenhuma hipótese o LICENCIADO terá acesso ao código fonte do
  APLICATIVO ora licenciado, por este se tratar de propriedade intelectual da
  LICENCIANTE.</p>

  <p class="my-body">&nbsp;</p>

  <p class="my-title">4.<span></span> Das Restrições</p>

  <p class="my-body">Em hipótese alguma é permitido ao LICENCIADO ou a terceiros,
  de forma geral:</p>

  <p class="my-body">Copiar, ceder, sublicenciar, vender, dar em locação ou em
  garantia, reproduzir, doar, alienar de qualquer forma, transferir total ou
  parcialmente, sob quaisquer modalidades, gratuita ou onerosamente, provisória
  ou permanentemente, o APLICATIVO objeto deste CLUF, assim como seus módulos,
  partes, manuais ou quaisquer informações a ele relativas;</p>

  <p class="my-body">Retirar ou alterar, total ou parcialmente, os avisos de
  reserva de direito existente no APLICATIVO e na documentação;</p>

  <p class="my-body">Praticar engenharia reversa, <span class=SpellE>descompilação</span>
  ou desmontagem do APLICATIVO.</p>

  <p class="my-body">&nbsp;</p>

  <p class="my-title">5. Do Prazo</p>

  <p class="my-body">O presente CLUF entra em vigor na data de seu aceite pelo
  LICENCIADO e vigorará por prazo INDETERMINADO. Este CLUF será automaticamente
  renovado por iguais períodos caso o LICENCIADO não se manifeste expressamente
  em contrário, nos mesmos termos e condições, inclusive no que se refere à
  eventuais penalidades. Este CLUF poderá ser rescindido conforme estabelecido
  abaixo neste instrumento. </p>

  <p class="my-body">&nbsp;</p>

  <p class="my-title">6. Da Remuneração e Forma de Pagamento</p>

  <p class="my-body">O LICENCIADO deverá pagar à LICENCIANTE o valor do plano de 
  licenciamento conforme estabelecido no ANEXO 1 deste contrato. Caso o LICENCIADO
  que tenha optado pelo plano de licenciamento FREE (gratuito), será liberado acesso 
  somente à 1 (um) usuário e demais condições estabelecidas no SITE no momento da 
  aquisição.</p>

  <p class="my-body">Para planos de licenciamento customizáveis, O LICENCIADO 
  deverá pagar à LICENCIANTE o valor acordado, entre as partes, para a execução 
  das customizações.</p>

  <p class="my-body">Caso o LICENCIADO, no decorrer da vigência do presente
  instrumento, opte por outro plano de licenciamento superior (upgrade), os
  valores serão alterados de acordo com o respectivo plano escolhido. 
  Se o LICENCIADO, no decorrer da vigência do presente instrumento, optar 
  por um plano de licenciamento inferior (downgrade), a alteração de valores 
  será efetuada no próximo mês, sendo vedada a devolução de valores já pagos</p>

  <p class="my-body">A falta de pagamento de quaisquer valores nas respectivas
  datas de vencimento não acarretará na rescisão automática do CLUF, mas causará
  a suspensão do acesso do LICENCIADO ao APLICATIVO até que as pendências
  financeiras tenham sido regularizadas. O acesso ao APLICATIVO somente será
  restabelecido após a identificação pela LICENCIANTE do pagamento integral de
  todos os valores devidos enquanto o mesmo esteve suspenso. 
  Caso o LICENCIADO não resolva a pendência financeira no prazo de 30 (trinta) dias
  contados do vencimento do valor não pago, a LICENCIANTE se reserva o direito de
  rescindir o presente CLUF.</p>

  <p class="my-body">Caso o LICENCIADO não resolva a pendência financeira no
  prazo de 90 (noventa) dias corridos, contados do vencimento do valor não pago,
  a LICENCIANTE se reserva ao de apagar de forma definitiva e irrecuperável todas
  as informações do LICENCIADO que por ventura estejam armazenadas no APLICATIVO.</p>

  <p class="my-body">A LICENCIANTE poderá, a seu critério, por mera liberalidade,
  oferecer descontos nos valores dos planos delicenciamento. Neste caso, os descontos 
  serão válidos por até 12 (doze) meses. A LICENCIANTE poderárenovar ou não os 
  percentuais de desconto, podendo ainda cancelar os descontos após o período de 
  validade, sem aviso prévio.</p>

  <p class="my-body">Os valores mensais dos planos de licenciamento estabelecidos no 
  ato do licenciamento do APLICATIVO serão reajustados a cada 12 meses, pelo índice 
  positivo do IGP-M da FGV, acumulado dos 12 (doze) meses imediatamente anteriores ao 
  aniversário do contrato. Na eventual cessação do IGP-M da FGV, fica convencionado 
  que será utilizado o índice que o substituir ou não sendo substituído, pela média 
  dos dois índices financeiros de maior percentual acumulado no período ou outro 
  índice eleito de comum acordo entre as partes, que preserve o equilíbrio contratual.</p>

  <p class="my-body">&nbsp;</p>

  <p class="my-title">7. Restituição das Informações</p>

  <p class="my-body">Suspenso o acesso do LICENCIADO ao APLICATIVO, a LICENCIANTE
  manterá as informações do LICENCIADO armazenadas no APLICATIVO pelo período de
  90 (noventa) dias, contados da suspensão de acesso. Durante este período, a
  LICENCIANTE tornará as informações do LICENCIADO disponíveis para serem
  extraídas do APLICATIVO em formato SQLITE para o plano FREE e MYSQL (texto)
  para os demais planos.</p>

  <p class="my-body">As informações referentes à
  data e hora de acesso e ao endereço de protocolo de internet utilizado pelo
  LICENCIADO para acessar o Site e o APLICATIVO permanecerão armazenadas pela
  LICENCIANTE por 6 (meses) a contar da data de cada acesso realizado,
  independentemente do término da relação jurídica e comercial entre a
  LICENCIANTE e o LICENCIADO, em cumprimento ao disposto no Artigo 15 da Lei nº
  12.965/2014, podendo ser armazenados por um período maior de tempo mediante
  ordem judicial.</p>

  <p class="my-body">&nbsp;</p>

  <p class="my-title">8. Das Obrigações do Licenciado</p>

  <p class="my-body">Manter pessoal treinado para a operação do APLICATIVO e para
  a comunicação com a LICENCIANTE e prover, sempre que ocorrerem quaisquer
  problemas com o APLICATIVO, toda a documentação, relatórios e demais
  informações que relatem as circunstâncias em que os problemas ocorreram,
  objetivando facilitar e agilizar os trabalhos;</p>

  <p class="my-body">Responder pelas INFORMAÇÕES e dados inseridas no APLICATIVO,
  pelo cadastramento, permissões, senhas e modo de utilização de seus usuários,
  incluindo, mas não se limitando, às informações fornecidas no que diz respeito
  aos métodos de pagamento (boletos), números de contas bancárias e a informações
  financeiras disponibilizadas às autoridades fiscais por meio do APLICATIVO no
  que diz respeito à emissão de notas fiscais ou quanto ao cumprimento das
  obrigações acessórias disponibilizadas através do APLICATIVO. A LICENCIANTE em
  hipótese alguma será responsável pelo conteúdo (INFORMAÇÕES, senhas, cópias de
  informações, etc.) incluído no APLICATIVO, não sendo, portanto, estas
  INFORMAÇÕES revisadas em momento algum. A responsabilidade pelas INFORMAÇÕES
  inseridas ou excluídas no APLICATIVO é sempre do LICENCIADO, sendo este o único
  responsável pela realização de backup das informações, especialmente antes da
  exclusão. A LICENCIANTE não será responsável pelo armazenamento de informações
  excluídas pelo LICENCIADO;</p>

  <p class="my-body">Certificar-se de que não está proibido por determinação
  legal e/ou contratual de passar INFORMAÇÕES, INFORMAÇÕES FINANCEIRAS,
  INFORMAÇÕES DE CONTA e INFORMAÇÕES PESSOAIS, bem como quaisquer outros dados à
  LICENCIANTE, necessários para a execução do serviço oferecido por este CLUF;</p>

  <p class="my-body">Garantir a regularidade dos dados pessoais inseridos no
  APLICATIVO, em especial, no que tange ao cumprimento de bases legais e direitos
  dos titulares de dados;</p>

  <p class="my-body">A qualquer tempo a LICENCIANTE poderá bloquear acesso ao
  APLICATIVO caso constate qualquer prática pelo LICENCIADO ou terceiros de
  violação ao presente Termo de uso e/ou qualquer tentativa de fraude ou dê a
  entender tratar-se de uma tentativa, não reduzindo essa ação a responsabilidade
  do LICENCIADO sobre seus atos.</p>

  <p class="my-body">Atender rigorosamente a legislação brasileira e toda
  obrigação legal imposta e/ou decorrente de sua atividade e em razão da
  utilização deste APLICATIVO.</p>

  <p class="my-body">Caso o LICENCIADO acredite que seu login e senha de acesso
  ao APLICATIVO tenham sido roubados ou sejam de conhecimento de outras pessoas,
  por qualquer razão, o LICENCIADO deverá imediatamente comunicar tal fato à
  LICENCIANTE.</p>

  <p class="my-body">8.1. Obriga-se o LICENCIADO ainda, em conformidade com a
  cláusula 11.1 deste Termo, a responder pelas INFORMAÇÕES inseridas no APLICATIVO para utilização das
  funcionalidades de configuração tributária e Automatização de Impostos, cabendo
  somente ao LICENCIADO a inserção correta de suas próprias informações e a
  revisão destas informações com um profissional de contabilidade. É de obrigação
  do LICENCIADO, ainda, a conferência das informações para garantia de tributação
  de alíquotas aplicáveis e adequação à legislação tributária vigente.</p>

  <p class="my-body">Em caso de utilização do APLICATIVO pelo CONTADOR do
  LICENCIADO, mediante autorização do LICENCIADO, o CONTADOR deverá assumir a
  responsabilidade pela conferência das informações inseridas no APLICATIVO,
  inclusive as informações detalhadas na cláusula 8.1, sendo responsável pela
  conferência das alíquotas aplicáveis e adequação à legislação tributária
  vigente.</p>

  <p class="my-body">&nbsp;</p>

  <p class="my-title">9. Das Obrigações do Licenciante</p>

  <p class="my-body">A LICENCIANTE garante ao LICENCIADO que o APLICATIVO deverá
  funcionar regularmente, se respeitadas as condições de uso definidas na
  documentação. Na ocorrência de falhas de programação (“bugs”), a LICENCIANTE
  obrigar-se-á a corrigir tais falhas, podendo a seu critério substituir a cópia
  dos programas com falhas por cópias corrigidas;</p>

  <p class="my-body">A LICENCIANTE manterá atualizada as funções existentes no
  APLICATIVO com relação às variáveis normalmente alteradas pela legislação
  federal. A interpretação legal das normas editadas pelo governo será efetuada
  com base no entendimento majoritário dos “usuários” do APLICATIVO,
  doutrinadores e jurisprudência pátria. Ficam excluídas da manutenção as
  alterações no APLICATIVO originárias de obrigações assumidas pelo LICENCIADO
  junto a sindicatos ou associações, tais como convenções coletivas de trabalho e
  outras, bem como necessidades específicas de certos segmentos, além do
  atendimento de legislações municipais e/ou estaduais.</p>

  <p class="my-body">Suspender o acesso ao APLICATIVO do LICENCIADO que esteja
  desrespeitando as regras de conteúdo aqui estabelecidas ou as normas legais em
  vigor;</p>

  <p class="my-body">Alterar as especificações e/ou características do APLICATIVO
  licenciados para a melhoria e/ou correções de erros, de acordo com o plano de
  produtos definido pela LICENCIANTE;</p>

  <p class="my-body">Disponibilizar acesso aos serviços de suporte compreendido de
  segunda a sexta-feira, a) das 08:00h às 18:00h (horário de Brasília), sem
  intervalo, via atendimento online (localizado no interior do APLICATIVO) e por
  meio de correio eletrônico suporte@inumero.com.br.</p>

  <p class="my-body">Manter as INFORMAÇÕES FINANCEIRAS, INFORMAÇÕES DE CONTA e
  INFORMAÇÕES PESSOAIS do LICENCIADO, bem como seus registros de acesso, em
  sigilo, em conformidade com as disposições da Lei nº 12.965/2014. A
  LICENCIANTE não será responsável pelo armazenamento de informações excluídas
  pelo LICENCIADO.</p>

  <p class="my-body">&nbsp;</p>

  <p class="my-title">10. Nível de Serviço</p>

  <p class="my-body">A LICENCIANTE empreenderá esforços comercialmente razoáveis
  para tornar o APLICATIVO disponível, no mínimo, 99,7% (noventa e nove vírgula
  sete por cento) durante cada Ano de Serviço. Na hipótese de a LICENCIANTE não cumprir o
  Compromisso de Nível de Serviço, o LICENCIADO terá o direito de receber o
  crédito correspondente a 1 (um) mês de mensalidade.</p>

  <p class="my-body">O Compromisso de Nível de Serviço não se aplica caso as
  circunstâncias de indisponibilidade resultem (i) de uma interrupção do
  fornecimento de energia elétrica ou paradas emergenciais não superiores a 2
  (duas) horas ou que ocorram no período das 24h às 6:00h (horário de Brasília);
  (<span class=SpellE>ii</span>) forem causadas por fatores que fujam ao razoável
  controle da LICENCIANTE, inclusive casos de força maior ou de acesso à Internet
  e problemas correlatos; (<span class=SpellE>iii</span>) resultem de quaisquer
  atos ou omissões do LICENCIADO, de terceiros ou de APLICATIVOS terceiros; (<span
  class=SpellE>iv</span>) resultem do equipamento, APLICATIVO ou outras
  tecnologias que o LICENCIADO usar que impeçam o acesso regular do APLICATIVO;
  (<span class=SpellE>v</span>) resultem de falhas de instâncias individuais não atribuíveis à
  indisponibilidade do LICENCIADO; (<span class=SpellE>vi</span>) resultem de alterações realizadas na
  forma de acesso a INFORMAÇÕES FINANCEIRAS e/ou INFORMAÇÕES DE CONTA do
  LICENCIADO pelas instituições financeiras; (<span class=SpellE>vii</span>)
  resultem de práticas de gerenciamento da rede que possam afetar sua qualidade.</p>

  <p class="my-body">&nbsp;</p>

  <p class="my-title">11. Isenção de Responsabilidade da Licenciante</p>

  <p class="my-body">Por falha de operação, operação por pessoas não autorizadas
  ou qualquer outra causa em que não exista culpa da LICENCIANTE;</p>

  <p class="my-body">Pelo cumprimento dos prazos legais do LICENCIADO para a
  entrega de documentos fiscais ou pagamentos de impostos;</p>

  <p class="my-body">Pelos danos ou prejuízos decorrentes de decisões
  administrativas, gerenciais ou comerciais tomadas com base nas informações
  fornecidas pelo APLICATIVO;</p>

  <p class="my-body">Por problemas definidos como “caso fortuito” ou “força
  maior”, contemplados pelo Art. 393 do Código Civil Brasileiro;</p>

  <p class="my-body">Por eventuais problemas oriundos de ações de terceiros que
  possam interferir na qualidade do serviço;</p>

  <p class="my-body">Por danos causados a terceiros em razão de conteúdo gerado
  pelo LICENCIANTE através de APLICATIVO;</p>

  <p class="my-body">Pela indisponibilidade ou lentidão de APLICATIVOS de terceiros
  que se conectam com o APLICATIVO por meio de API ou ainda, de fornecedores de 
  telecomunicação do LICENCIADO;</p>

  <p class="my-body">Por eventuais infrações legais cometidas pelo LICENCIADO, de
  ordem fiscal, tributária, trabalhista, previdenciária, criminal, ou qualquer
  outra.</p>

  <p class="my-body">Pela geração e envio de obrigações fiscais acessórias,
  cabendo este procedimento somente ao LICENCIADO.</p>

  <p class="my-body">A LICENCIANTE adota as medidas de segurança adequadas de
  acordo com os padrões de mercado para a proteção das INFORMAÇÕES do LICENCIADO
  armazenadas no APLICATIVO. Contudo, o LICENCIADO reconhece que nenhum sistema, 
  servidor ou APLICATIVO
  está absolutamente imune a ataques e/ou invasões de hackers e outros agentes
  maliciosos, não sendo a LICENCIANTE responsável por qualquer exclusão,
  obtenção, utilização ou divulgação não autorizada de INFORMAÇÕES resultantes de
  ataques que a LICENCIANTE não poderia razoavelmente evitar por meio dos
  referidos padrões de segurança.</p>

  <p class="my-body">11.1. Conforme o uso do APLICATIVO pelo LICENCIADO, este
  pode sugerir naturezas de operações ou os impostos de acordo com o histórico de
  uso, especialmente na utilização das funcionalidades de configuração tributária
  e Automatização de Impostos. Em conformidade com a cláusula 8.1., a LICENCIANTE
  não se responsabiliza pelo aceite e pelo preenchimento incorreto dessas
  informações, cabendo somente ao LICENCIADO a checagem correta de suas próprias
  informações inseridas, sendo necessário ao LICENCIADO sempre revisar as regras
  de automatização de impostos e configuração tributária com um profissional de
  contabilidade, considerando que o APLICATIVO oferece sugestões com base em histórico
  de regras de tributação conforme legislação, sendo responsabilidade exclusiva
  do LICENCIADO a conferência das informações de suas notas fiscais e se houve
  alteração de alíquotas conforme legislação tributária vigente. A LICENCIANTE
  ressalta que o LICENCIADO poderá revisar e atualizar as regras de configuração
  tributária e Automatização de Impostos a qualquer tempo para se adequar à
  legislação vigente. Caso as informações sejam inseridas pelo CONTADOR do
  LICENCIADO, mediante autorização do LICENCIADO, o CONTADOR será responsável
  pela veracidade das informações bem como pela conferência das alíquotas
  aplicáveis e adequação à legislação vigente, nos termos da cláusula 8.1.</p>

  <p class="my-body">&nbsp;</p>

  <p class="my-title">12. Da Retomada dos APLICATIVOS</p>

  <p class="my-body">A LICENCIANTE se reserva o direito de cancelar imediatamente
  o acesso do LICENCIADO ao APLICATIVO nos casos em que o LICENCIADO usar o
  APLICATIVO de forma diversa daquela estipulada no presente instrumento</p>

  <p class="my-body">&nbsp;</p>

  <p class="my-title">13. Das Garantias Limitadas</p>

  <p class="my-body">Na extensão máxima permitida pela lei em vigor, o APLICATIVO
  é fornecido “no estado em que se encontra” e “conforme a disponibilidade”, com
  todas as falhas e sem garantia de qualquer espécie.</p>

  <p class="my-body">Não obstante o disposto no item 9.a acima, a LICENCIANTE não
  garante que as funções contidas no APLICATIVO atendam às suas necessidades, que
  a operação do APLICATIVO será ininterrupta ou livre de erros, que qualquer
  serviço continuará disponível, que os defeitos no APLICATIVO serão corrigidos
  ou que o APLICATIVO será compatível ou funcione com qualquer APLICATIVO,
  aplicações ou serviços de terceiros.</p>

  <p class="my-body">Além disso, o LICENCIADO reconhece que o APLICATIVO não deve
  ser utilizado ou não é adequado para ser utilizado em situações ou ambientes
  nos quais a falha ou atrasos, os erros ou inexatidões de conteúdo, dados ou
  informações fornecidas pelo APLICATIVO possam levar à morte, danos pessoais, ou
  danos físicos ou ambientais graves, incluindo, mas não se limitando, à operação
  de instalações nucleares, sistemas de navegação ou de comunicação aérea,
  controle de tráfego aéreo, sistemas de suporte vital ou de armas.</p>

  <p class="my-body">&nbsp;</p>

  <p class="my-title">14.<span></span>Limitação de Responsabilidade</p>

  <p class="my-body">Em nenhum caso a LICENCIANTE será responsável por danos
  pessoais ou qualquer prejuízo incidental, especial, indireto ou consequente,
  incluindo, sem limitação, prejuízos por perda de lucro, corrupção ou perda de
  dados, falha de transmissão ou recepção de dados, não continuidade do negócio
  ou qualquer outro prejuízo ou perda comercial, decorrentes ou relacionados ao
  seu uso ou sua inabilidade em usar o APLICATIVO, por qualquer outro motivo. Sob
  nenhuma circunstância a responsabilidade integral da LICENCIANTE com relação ao
  LICENCIADO por todos os danos excederá a quantia correspondente ao último plano
  de licenciamento pago pelo LICENCIADO à LICENCIANTE pela obtenção da presente
  licença de APLICATIVO.</p>

  <p class="my-body">&nbsp;</p>

  <p class="my-title">15. Da Rescisão</p>

  <p class="my-body">A LICENCIANTE poderá rescindir este CLUF a qualquer tempo,
  desde que comunique ao LICENCIADO, com antecedência mínima de 15 (quinze) dias,
  devendo neste caso quitar todos os débitos pendentes. Este prazo de antecedência
  mínima não se aplicarão nos casos previstos na cláusula
  12 acima e nos casos de violação do presente instrumento.</p>

  <p class="my-body">A LICENCIANTE poderá rescindir o CLUF a qualquer momento em
  caso de violação pelo LICENCIADO dos termos e condições ora acordados, ou em
  caso de atraso de pagamento não sanado no prazo de 90 (noventa) dias, conforme
  cláusula 6 acima.</p>

  <p class="my-body">No caso de rescisão do presente contrato, os dados pessoais,
  bancários, financeiros e demais informações do LICENCIADO ficarão disponíveis
  conforme cláusula 7 deste termo, sendo excluídos permanentemente após 90
  (noventa) dias da data da rescisão.</p>

  <p class="my-body">&nbsp;</p>

  <p class="my-title">16. Das disposições Legais</p>

  <p class="my-body">O LICENCIADO não poderá prestar serviços a terceiros
  utilizando o APLICATIVO da LICENCIANTE sem autorização prévia e expressa da
  LICENCIANTE; </p>

  <p class="my-body">Caso o LICENCIADO venha a desenvolver um novo módulo ou
  produto, será considerado como parte do APLICATIVO
  fornecido pela LICENCIANTE, ficando, portanto, sua propriedade incorporada pela
  LICENCIANTE e seu uso condicionado a estas cláusulas contratuais;</p>

  <p class="my-body">Este CLUF obriga as partes e seus sucessores e somente o
  LICENCIADO possui licença não exclusiva para a utilização do APLICATIVO,
  sendo-lhe, entretanto, vedado transferir os direitos e obrigações acordados por
  este instrumento. Tal limitação, no entanto, não atinge a LICENCIANTE, que
  poderá, a qualquer tempo, ceder, no todo ou em parte, os direitos e obrigações
  inerentes ao presente CLUF;</p>

  <p class="my-body">Não constitui causa de rescisão contratual o não cumprimento
  das obrigações aqui assumidas em decorrência de fatos que independam da vontade
  das partes, tais como os que configuram o caso fortuito ou força maior,
  conforme previsto no artigo 393 do Código Civil Brasileiro;</p>

  <p class="my-body">Se qualquer <span class=SpellE>di</span> posição deste CLUF
  for considerada nula, anulável, inválida ou inoperante, nenhuma outra
  disposição deste CLUF será afetada como consequência disso e, portanto, as
  disposições restantes deste CLUF permanecerão em pleno vigor e efeito como se
  tal disposição nula, anulável, inválida ou inoperante não estivesse contida
  neste CLUF;</p>

  <p class="my-body">O LICENCIADO concorda que a LICENCIANTE possa divulgar a
  celebração deste instrumento para fins comerciais, fazendo menção ao nome e à
  marca do LICENCIADO em campanhas comerciais;</p>

  <p class="my-body">&nbsp;</p>

  <p class="my-title">17.<span></span>Da Lei Aplicável</p>

  <p class="my-body">Este CLUF será regido, interpretado e se sujeitará às leis
  brasileiras e, o LICENCIADO e a LICENCIANTE desde logo elegem, de forma
  irrevogável e irretratável, o foro da Comarca da Cidade de Curitiba, Estado de
  Paraná, para dirimir quaisquer dúvidas ou controvérsias oriundas deste
  CLUF, com a exclusão de qualquer outro, por mais privilegiado que seja. </p>

  <p class="my-body">&nbsp;</p>

  <p class="my-body">Curitiba/PR, 03 de novembro de 2020</p>

  <p class="my-body">INÚMERO SISTEMAS LTDA <br><small>CLUF versão 1.0</small></p>
  </div>
</div>
</template>

<script>
  export default {
    props: {
      titlable: { type: Boolean, default: true }
    },

    methods: {
      print () {
        let w = window.open()
        let html = document.getElementById('contrato').innerHTML
        let title = '<head><title>INÚMERO</title></head><h3>CONTRATO DE LICENÇA DE USUÁRIO FINAL - INÚMERO SISTEMAS</h3>'
        w.document.write(title + html)
        w.print()
        w.close()
      }
    }
  }
</script>

<style scoped>
  .my-title {
    font-size: 12pt;	
    font-weight: bold;
    color: black;
  }
  .my-body {
    font-size: 10.5pt;
    color: black;
    line-height: 1.3rem;
  }
  .SpellE {
    font-style: italic;
  }
</style>