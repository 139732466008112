<template>
  <my-crud
    caption="Aplicativos"
    singular="Aplicativo"
    :deletable="false"
    :showUnique="false"
  >
    <template v-slot="props">
      <my-crud
        name="instancia"
        :main="props.main"
        :current="getCurrent()"
        caption="Instâncias"
        singular="Instância"
        :creatable="false"
        :editable="true"
        :deletable="false"
        :searchable="false"
        :attach="false"
        :showUnique="false"
        :iconFavorite="false"
      />
    </template>
  </my-crud>
</template>

<script>
  export default {
    data: () => ({
      main: {
        fields: {
          id: {}, 
          nome: { text: 'Nome curto' },
          descricao: { text: 'Descrição', class: 'xs12 md6' },
          bloqueado: {
            text: 'Status',
            default: '0',
            items: [
              { value: '0', text: 'Ativo' },
              { value: '1', text: 'Bloq.nova inst.' },
              { value: '2', text: 'Blooqueado uso' }
            ],
            rules: [(v) => !!v || 'Requerido']
          },
          serverhost: { text: 'Endereço servidor', class: 'xs12 md6' },
          usuario_id: { text: 'Proprietário', link: '/usuario', class: 'xs12 md4' },
          criadoEm: { text: 'Criado em', readonly: true },
          observacao: { text: 'Observação', class: 'xs12 md6' },
          foto: {
            type: 'img',
            sortable: false,
            html: function (value) {
              return '<img width="40" src="' + value + '">'
            },
            class: 'xs12 md4'
          }
        },
        sorted: true,
        columns: ['foto', 'id', 'usuario_id', 'nome', 'descricao', 'serverhost', 'observacao', 'criadoEm', 'bloqueado']
      },

      instancia: {
        fields: {
          id: { text: 'Instância', readonly: true },
          app_id: { gridable: false, editable: false, readonly: true },
          status: {
            items: [
              { value: '0', text: 'Instalar' },
              { value: '1', text: 'Instalado' },
              { value: '2', text: 'Upgrade' }
            ],
            readonly: true
          },
          plano: {
            items: [
              { value: '0', text: 'FREE' },
              { value: '1', text: 'Bronze' },
              { value: '2', text: 'Prata' },
              { value: '3', text: 'Ouro' },
              { value: '4', text: 'Diamante' }
            ],
            readonly: true
          },
          nome: { text: 'Empresa', readonly: true },
          cnpj: { text: 'CNPJ', readonly: true },
          mensagem: { text: 'Mensagem', class: 'xs12 md6' },
          serverhost: { text: 'Endereço servidor', class: 'xs12 md6' },
          usuario_id: { text: 'Proprietário', link: '/usuario', class: 'xs12 md4', readonly: true },
          qdeUsuario: { text: 'Qde usuários' },
          qdeSuporte: { text: 'Qde suporte' },
          criadoEm: { text: 'Criado em', readonly: true },
          observacao: { text: 'Observação', class: 'xs12 md6' },
          bloqueado: { },
          excluir: { text: 'Excluído em', readonly: true },
          foto: {
            type: 'img',
            sortable: false,
            editable: false,
            readonly: true,
            html: function (value) {
              return '<img width="40" src="' + value + '">'
            }
          }
        },
        sorted: true,
        columns: ['foto', 'id', 'app_id', 'usuario_id', 'nome', 'cnpj', 'mensagem', 'serverhost', 'criadoEm', 'bloqueado']
      }
    }),

    methods: {
      getCurrent () {
        return this
      }
    }
  }
</script>

